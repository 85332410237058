import { useEffect, useState, useCallback, useRef } from "react"
import { useParams } from "react-router-dom"
import { useSideBarMenuContext } from "../components/Sidebar/SideBarContext"
import { get, del, put } from "../helpers/Requests"
import { ServerData, UpdateServerProps } from "../types/types"
import { Dialog } from "@headlessui/react"


function UpdateServer({ isOpen, setIsOpen, id, server, setServer}: UpdateServerProps) {
    const [type, setType] = useState('')
    const [url, setUrl] = useState('')
    const [key, setKey] = useState('')
    const [name, setName] = useState('')
    const [cloud, setCloud] = useState('')
    const [dataSet, setDataSet] = useState(false)
    const { setUp } = useSideBarMenuContext()
    const cancelButtonRef = useRef<HTMLButtonElement>(null);
    

    useEffect(() => {
        setUp(null)
    }, [setUp])
    
    const updateServer = useCallback(() => {
        put(`servers/${id}`, { type, short_name: name, url, key, cloud: cloud })
            .then(setServer)
            .then(() => {setIsOpen(false)})
            .catch(err => {
                alert(err)
                console.log(err)
            })
    }, [type, url, key, name, cloud, setIsOpen, id, setServer])

    const [servers, setServers] = useState<ServerData[]>([])
    useEffect(() => {
        get('servers')
            .then(s => {
                setServers(s)
            })
    }, [updateServer])


    let s = server()
    if (s !== undefined && !dataSet) {
        setType(s.type)
        setUrl(s.url)
        setKey(s.key)
        setName(s.short_name)
        setCloud("" + s.cloud)
        setDataSet(true)
    }
  
    return (
        <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef}>
            <div className="flex items-center justify-center min-h-screen">
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

                <div className="relative bg-white rounded max-w-sm mx-auto p-5 drop-shadow-lg">
                    <Dialog.Title className="text-2xl">Edit Server</Dialog.Title>

                    <label className="block text-gray-900 text-sm font-bold mb-2">Server Type</label>
                    <select value={type} onChange={e => setType(e.target.value)} className="block shadow appearance-none border rounded w-full py-2 px-3 text-gray-900 mb-5" >
                            <option value="cloud">Cloud</option>
                            <option value="apa">APA</option>
                            <option value="reporting">Reporting</option>
                    </select>

                    <label className="block text-gray-900 text-sm font-bold mb-2">Server Name</label>
                    <input value={name} onChange={e => setName(e.target.value)} type="text" placeholder="Server Name" className="block shadow appearance-none border rounded w-full py-2 px-3 text-gray-900 mb-5" />

                    <label className="block text-gray-900 text-sm font-bold mb-2">Server URL</label>
                    <input value={url} onChange={e => setUrl(e.target.value)} type="text" placeholder="Server URL" className="block shadow appearance-none border rounded w-full py-2 px-3 text-gray-900 mb-5" />
                   
                    <label className="block text-gray-900 text-sm font-bold mb-2">Server Key</label>
                    <input value={key} onChange={e => setKey(e.target.value)} type="text" placeholder="Server Key" className="block shadow appearance-none border rounded w-full py-2 px-3 text-gray-900 mb-5" />
                    
                    {/* <label className="block text-gray-900 text-sm font-bold mb-2">Server Deleted</label>
                    <input onChange={e => setDeleted(e.target.checked)} type="checkbox" placeholder="Server Key" className="block shadow appearance-none border rounded py-2 px-3 text-gray-900 mb-5" /> */}
                    { (type === "apa" || type === "reporting") &&
                    <span>
                        <label className="block text-gray-900 text-sm font-bold mb-2">Cloud Server</label>
                        <select value={cloud} onChange={e => setCloud("" + e.target.value)} className="block shadow appearance-none border rounded w-full py-2 px-3 text-gray-900 mb-5" >
                            <option value="">Pick one</option>
                            {servers.filter(item => item.type === "cloud").map(item => <option key={item.id} value={item.id}>{item.short_name}</option>)}
                    </select>
                    </span>
                  }

                    <button
                        onClick={updateServer}  
                        disabled={url.length === 0 || type.length === 0 || key.length === 0 || name.length === 0}
                        className="bg-green-700 hover:bg-green-900 text-white font-bold py-2 px-4 rounded disabled:bg-green-300 disabled:hover:bg-green-300 mr-2">
                        Save
                    </button>

                    <button
                        ref={cancelButtonRef}
                        onClick={() => setIsOpen(false)}
                        className="bg-gray-700 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded disabled:bg-gray-300 disabled:hover:bg-gray-300">
                        Cancel
                    </button>
                </div>
            </div>
        </Dialog>
    )
}


const Server = () => {
    const { id } = useParams()
    const [editServer, setEditServer] = useState(false)
    const [server, setServer] = useState<ServerData>()
    const [servers, setServers] = useState<ServerData[]>([])
    
    useEffect(() => {
        get('servers')
            .then(s => {
                setServers(s)
            })
    }, [id])
    const { setUp } = useSideBarMenuContext()

    useEffect(() => {
        setUp("/servers")

        get(`servers/${id}`)
            .then(setServer)
            .catch(err => {
                console.log(err)
            })
    }, [id, setUp])


    const removeServer = useCallback(() => {
        del(`servers/${id}`)
            .then(() => {
                
            })
            .catch(err => {
                alert(err)
                console.log(err)
            })
    }, [id])


    return <>

{editServer && (
    <UpdateServer isOpen={true} setIsOpen={setEditServer} id={id === undefined ? "Error" : id} server={() =>{ return server;}} setServer={setServer} />
    )}

    <div className="relative bg-white mx-auto p-5">
        <p>
        <label className="block text-gray-900 text-sm font-bold mb-2">Server Id</label>
        {server?.id}
        </p>
        <br />
        <p>
        <label className="block text-gray-900 text-sm font-bold mb-2">Server Type</label>
        {server?.type}
        </p>
        <br />
        <p>
        <label className="block text-gray-900 text-sm font-bold mb-2">Server Name</label>
        {server?.short_name}
        </p>
        <br />
        <p>
        <label className="block text-gray-900 text-sm font-bold mb-2">Server URL</label>
        {server?.url}
        </p>
        <br />
        <p>
        <label className="block text-gray-900 text-sm font-bold mb-2">Server Key</label>
        {server?.key}
        </p>
        
        <p>
        <label className="block text-gray-900 text-sm font-bold mb-2">Cloud Server</label>
        {servers.find(x => x.id === server?.cloud)?.short_name}
        </p>
        <td className="px-4 py-2">{}</td>


        <br />
        <button onClick={() => setEditServer(true)}
                className="bg-blue-700 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded">
                Edit
        </button>
        &nbsp;
        <button onClick={() => { if (window.confirm("Are you sure you want to remove this server?")) removeServer()}}
                className="bg-red-700 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded">
                Delete
        </button>
                   
    </div>


    </>
}

export default Server