import { Store } from "../contexts/StoreContext";

export function orgsReducer(state: Store, action:any) {
    switch (action.type) {
        case 'change_org': {
            const orgs = [...state.orgs]
            const index = orgs.findIndex(o => action.id === o.id)
            orgs.splice(index, 1, action.org)
            return orgs
        }
        case 'remove_org': {
            return state.orgs.filter(t => t.id !== action.id)
        }
        case 'set_orgs': {
            console.log(state)
            return  action.orgs
        }
        default: {
            return state.orgs;
        }
    }
}
