import { Switch } from '@headlessui/react'

const CustomSwitch: React.FC<{ enabled: boolean, onChange(bool: boolean): any }> = ({ enabled, onChange }) => {
    return (
        <div className="">
            <Switch
                checked={enabled}
                onChange={_ => onChange(!enabled)}
                className={`${enabled ? 'bg-tangerine-900' : 'bg-tangerine-200'}
          relative inline-flex flex-shrink-0 h-4 w-9 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
                <span className="sr-only">Use setting</span>
                <span
                    aria-hidden="true"
                    className={`${enabled ? 'translate-x-5' : 'translate-x-0'}
            pointer-events-none inline-block h-3 w-3 rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
                />
            </Switch>
        </div>
    )
}

export default CustomSwitch
