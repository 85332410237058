import React, { Context, createContext, useContext, useEffect, useReducer, useState } from "react";
import { APAAccount, APALatestRun, APARun, ServerData, User } from "../types/types";
import { CloudOrgInfo } from "../types/cloud_types";
import mainReducer from "../reducers";

export interface Store {
    orgs: CloudOrgInfo[],
    accounts: APAAccount[],
    latest_runs: APALatestRun[],
    servers: ServerData[]
}

const initialState: Store = {
    orgs: [],
    accounts: [],
    latest_runs: [],
    servers: []
}

export const StoreContext = createContext<Store>(initialState)

export const AppDispatchContext = createContext<any>(null)

export function StoreProvider(props: any) {
    initialState.servers = props.data.servers;

    const [states, dispatch] = useReducer(mainReducer, initialState)

    return (

        <StoreContext.Provider value={states}>
            <AppDispatchContext.Provider value={dispatch}>
                {props.children}
            </AppDispatchContext.Provider>
        </StoreContext.Provider>

    )
}

export const useStore = () => useContext(StoreContext)
export const useDispatch = () => useContext(AppDispatchContext)