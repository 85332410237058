type Color = "default" | "primary" | "secondary" | "alert" | "success" | "none";
interface ButtonProps extends React.ComponentPropsWithoutRef<"button"> {
    text: string;
    color: Color;
    loading?: boolean;
    icon?: React.ReactNode; // remember aria-hidden="true" when passing icon
}

export const secondaryButtonColor = "hover:bg-dark-forest-200 text-dark-forest-700 border-dark-forest-100 border border-solid bg-dark-forest-100 focus:border-dark-forest-400 focus:ring focus:ring-dark-forest-300 focus:ring-opacity-50"

const colors: { [key: string]: string } = {
    default: " text-gray-600 border-gray-300 border border-solid focus:border-dark-forest-300 focus:ring focus:ring-dark-forest-200 focus:ring-opacity-50 hover:bg-gray-100",
    primary: "hover:bg-dark-forest-900 text-gray-100 border-dark-forest-700 border border-solid bg-dark-forest-700 focus:border-dark-forest-400 focus:ring focus:ring-dark-forest-300 focus:ring-opacity-50",
    secondary: secondaryButtonColor,
    alert: "bg-red-600 text-white hover:bg-red-700",
    success: "bg-green-600 text-white hover:bg-green-700",
    none: "text-dark-forest-700",
}

const Button: React.FC<ButtonProps> = ({ text, className, color, loading = false, icon, disabled, ...props }) => {
    return (
        <button
            className={`${className} ${colors[color]} ${color !== "none" ? "shadow-sm" : ""} ${disabled ? "opacity-50 cursor-not-allowed" : ""} py-2 text-sm px-4 rounded-lg font-medium min-w-[60px] inline-flex whitespace-nowrap items-center`}
            {...props}
            disabled={disabled}
        >
            {loading && <Loader />}
            {icon && <span className="mr-2">{icon}</span>}
            {text}
        </button>
    )
}

const Loader = () => {
    return <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
}

export default Button