import moment from "moment"
import { useCallback, useEffect, useRef, useState } from "react"
import { BsCloudDownload } from "react-icons/bs"
import { GrPlayFill, GrResume } from "react-icons/gr"
import { HiDotsCircleHorizontal, HiTrash } from "react-icons/hi"
import { MdDangerous } from "react-icons/md"
import { useParams, useLocation  } from "react-router-dom"
import AddSchedule from "../components/APA/AddSchedule"
import AddSetup from "../components/APA/AddSetup"
import Button from "../components/Button"
import MenuDropdown from "../components/MenuDropdown"
import MenuDropdownItem from "../components/MenuDropdownItem"
import { Table, TableBody, TableBodyCell, TableBodyRow, TableHead, TableHeadCell, TableHeadRow } from "../components/Table"
import { del, get, getRaw, post, postRaw, put } from "../helpers/Requests"
import { APAAccount, APARun, APASchedule, APAScheduleRun, APASetup } from "../types/types"
import { BsBugFill} from 'react-icons/bs'
import { Dialog } from "@headlessui/react"

type APAOrganizationProps = {
	serverId?: string
	accountID?: string
}
const APAOrganization = (props: APAOrganizationProps) => {
	let { serverId, accountID } = useParams()
	const [account, setAccount] = useState<APAAccount | null>(null)
	const [setups, setSetups] = useState<APASetup[]>([])
	const [schedules, setSchedules] = useState<APASchedule[]>([])
	const [scheduledRuns, setScheduledRuns] = useState<APAScheduleRun[]>([])
	const [logs, setLogs] = useState<APARun[]>([])
	const [addAccountOpen, setAddAccountOpen] = useState<boolean>(false)
	const [markedSetup, setMarkedSetup] = useState<number | null>(null)
	const [addSetupOpen, setAddSetupOpen] = useState<boolean>(false)
	const [openedError, setOpenedError] = useState<number>(-1)
	const [popupError, setPopupError] = useState<string>("")
	const location = useLocation()
	const logsRef = useRef<HTMLDivElement>(null)
	

	const MAX_LOGS = 25

	useEffect(() => {
		if (!serverId) {
			serverId = props.serverId;
		}

		if (!accountID) {
			accountID = props.accountID;
		}
	}, [serverId, accountID])

	useEffect(() => {
		if(location.hash === "#logs" && !!logsRef.current && logs) {
				logsRef.current.scrollIntoView()
		}
	}, [location, logsRef, logs])

	const refreshData = useCallback(() => {
		get(`apa/${serverId}/setups/account/${accountID}`)
			.then(setups => setSetups(setups))
			.catch(e => console.log(e))

		get(`apa/${serverId}/schedules/account/${accountID}`)
			.then(schedules => setSchedules(schedules))
			.catch(e => console.log(e))

		get(`apa/${serverId}/schedules/runs/account/${accountID}`)
			.then(scheduledRuns => setScheduledRuns(scheduledRuns))
			.catch(e => console.log(e))

		get(`apa/${serverId}/logs/account/${accountID}`)
			.then(logs => setLogs(logs))
			.catch(e => console.log(e))

		setMarkedSetup(null)
	}, [accountID, serverId])

	const downloadFile = useCallback((s: APASetup) => {
		postRaw(`apa/${serverId}/setups/${s.id}/download`, null)
			.then(res => res.blob())
			.then(data => {
				const blob = new Blob([data], { type: 'application/zip' })
				const link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				link.download = `${s.account_id}_setup_${s.id}.aut`
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
				setMarkedSetup(null)
			})
			.catch(e => alert(`Failed to download file: ${e}`))
	}, [serverId])

	const doShowLog = useCallback((id: number) => {
		getRaw(`apa/${serverId}/logs/${id}/log`)
		.then(res => res.blob())
		.then(data => {
			const blob = new Blob([data], { type: 'text/plain' })
			
			blob.text()
			.then(text => {
				setPopupError(text)
				setOpenedError(id)
			})
			.catch(e => alert(`Failed to show log: ${e}`))
		})
		.catch(e => alert(`Failed to show log: ${e}`))
	}, [serverId])


	const doDownloadLog = useCallback((id: number) => {
		getRaw(`apa/${serverId}/logs/${id}/log`)
			.then(res => res.blob())
			.then(data => {
				const blob = new Blob([data], { type: 'text/plain' })
				const link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				link.download = `log_${id}.txt`
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
				setMarkedSetup(null)
			})
			.catch(e => alert(`Failed to download log: ${e}`))
	}, [serverId])

	const doDownloadError = useCallback((id: number) => {
		getRaw(`apa/${serverId}/logs/${id}/error`)
			.then(res => res.blob())
			.then(data => {
				const blob = new Blob([data], { type: 'text/plain' })
				const link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				link.download = `error_${id}.txt`
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
				setMarkedSetup(null)
			})
			.catch(e => alert(`Failed to download error: ${e}`))
	}, [serverId])

	const doDownloadStats = useCallback((id: number) => {
		getRaw(`apa/${serverId}/logs/${id}/stats`)
			.then(res => res.blob())
			.then(data => {
				const blob = new Blob([data], { type: 'text/plain' })
				const link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				link.download = `stats_${id}.txt`
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
				setMarkedSetup(null)
			})
			.catch(e => alert(`Failed to download stats: ${e}`))
	}, [serverId])

	const quarantineSetup = useCallback((s: APASetup) => {
		put(`apa/${serverId}/setups/${s.id}/quarantine`, null)
			.then(() => refreshData())
			.catch(e => console.log(e))
	}, [serverId, refreshData])

	const unquarantineSetup = useCallback((s: APASetup) => {
		put(`apa/${serverId}/setups/${s.id}/unquarantine`, null)
			.then(() => refreshData())
			.catch(e => console.log(e))
	}, [serverId, refreshData])

	const enqueueSetup = useCallback((s: APASetup) => {
		post(`apa/${serverId}/setups/${s.id}/run`, null)
			.then(() => refreshData())
			.catch(e => console.log(e))
	}, [serverId, refreshData])

	const enqueueSpecialSetup = useCallback((s: APASetup) => {
		post(`apa/${serverId}/setups/${s.id}/runskipmonitor`, null)
			.then(() => refreshData())
			.catch(e => console.log(e))
	}, [serverId, refreshData])
	

	const deleteSetup = (s: APASetup) => {
		if (window.confirm("Are you sure you want to delete this setup? (this will also delete all associated schedules, runs and logs!!!)")) {
			del(`apa/${serverId}/setups/${s.id}`)
				.then(() => refreshData())
				.catch(e => console.log(e))
		}
	}

	const doDeleteSchedule = (id: number) => {
		if (window.confirm("Are you sure you want to delete this schedule?")) {
			del(`apa/${serverId}/schedules/${id}`)
				.then(() => {
					refreshData()
				})
				.catch(e => console.log(e))
		}
	}

	const formatDuration = (d: moment.Duration) : string =>{
		let hours = Math.floor(d.asHours())
		let minutes = d.asMinutes() % 60
		if(minutes === 0) return (`${hours} hour` + (hours === 1 ? '' : 's'))
		return (`${hours} hour` + (hours === 1 ? '' : 's')) + ` ${minutes} minute${minutes === 1 ? '' : 's'}`
	}

	const showDays = (days: string) : string => {
		if(days === "") //backwards compability
			return "Mo Tu We Th Fr Sa Su"

		let isoNames = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"]

		let dayArray = JSON.parse(days)
		let result = ""
		for(let i = 0; i < dayArray.length; i++){
			if(dayArray[i])
				result += isoNames[i] + " "
			else 
				result += "   "
		}

		return result.slice(0, -1);
	}

	useEffect(() => {
		get(`apa/${serverId}/accounts/${accountID}`)
			.then(a => setAccount(a))
			.catch(e => console.log(e))
		refreshData()
	}, [accountID, refreshData, serverId])


	return <div className="bg-gray-100 h-full">
		<div className="bg-white p-8 border-b border-gray-200">
			<div className="flex justify-between items-center mb-4 pt-2 px-4">
				<h1 className="font-bold text-3xl mb-10">{account?.name}</h1>
				{/* <div className="text-gray-500 text-lg font-semibold"></div> */}
				<div><Button text='Refresh' color="default" onClick={_ => { refreshData() }} /></div>
			</div>

			<div className="flex justify-between items-end mb-4">
				<h2 className="font-bold text-2xl mb-10">Setups</h2>

				<Button text="Add Setup" color="primary" onClick={_ => setAddSetupOpen(true)} />
				<AddSetup serverId={serverId === undefined ? "" : serverId} setups={setups} account={Number(accountID)} open={addSetupOpen} onClose={() => { setAddSetupOpen(false); refreshData() }} />
			</div>

			<div>
				<Table>
					<TableHead className="bg-black bg-opacity-50 text-white">
						<TableHeadRow>
							<TableHeadCell>ID</TableHeadCell>
							<TableHeadCell>Description</TableHeadCell>
							<TableHeadCell>Quarantined</TableHeadCell>
							<TableHeadCell>Creation Time</TableHeadCell>
							<TableHeadCell>Schedules</TableHeadCell>
							<TableHeadCell>Runs</TableHeadCell>
							<TableHeadCell></TableHeadCell>
						</TableHeadRow>
					</TableHead>
					<TableBody>
						{setups.map((s, i) => <TableBodyRow onClick={() => setMarkedSetup(markedSetup && markedSetup === s.id ? null : s.id)} marked={markedSetup === s.id} key={i}>
							<TableBodyCell>{s.id}</TableBodyCell>
							<TableBodyCell>{s.description}</TableBodyCell>
							<TableBodyCell>{s.quarantined ? <MdDangerous className="w-5 h-5 mr-2 text-red-700 opacity-80" /> : "No"}</TableBodyCell>
							<TableBodyCell><span title={moment.unix(s.created_at).toLocaleString()}>{moment.unix(s.created_at).fromNow()}</span></TableBodyCell>
							<TableBodyCell>{schedules.filter(sc => sc.setup_id === s.id).length}</TableBodyCell>
							<TableBodyCell>{scheduledRuns.filter(sr => sr.setup_id === s.id).length}</TableBodyCell>
							<TableBodyCell>
								<MenuDropdown color={"icon"} expand="left" icon={<HiDotsCircleHorizontal className="w-5 h-5 text-indigo-700 transition duration-300 transform hover:scale-110 cursor-pointer" aria-hidden="true" />}>
									<div className="px-1 py-1 ">
										<MenuDropdownItem onClick={() => { downloadFile(s) }} text={"Download"} icon={<BsCloudDownload className="w-5 h-5 mr-2 opacity-80" />} />
										<MenuDropdownItem onClick={() => { if (s.quarantined) { unquarantineSetup(s) } else { quarantineSetup(s) } }} text={s.quarantined ? 'Un-quarantine' : 'Quarantine'} icon={s.quarantined ? <GrResume className="w-5 h-5 mr-2 opacity-80" /> : <MdDangerous className="w-5 h-5 mr-2 text-red-700 opacity-80" />} />
										<MenuDropdownItem onClick={() => { enqueueSetup(s) }} text={"Run now"} icon={<GrPlayFill className="w-5 h-5 mr-2 opacity-80" />} />
										<MenuDropdownItem onClick={() => { enqueueSpecialSetup(s) }} text={"Run now (ignore monitors)"} icon={<GrPlayFill className="w-5 h-5 mr-2 opacity-80" />} />
										<MenuDropdownItem onClick={() => { deleteSetup(s) }} text={"Delete"} icon={<HiTrash className="w-5 h-5 mr-2 text-red-700 opacity-80" />} />
									</div>
								</MenuDropdown>

							</TableBodyCell>
						</TableBodyRow>)}
					</TableBody>
				</Table>
			</div>

			<div className="flex justify-between items-end mb-4">
				<h2 className="font-bold text-2xl my-10">Schedules</h2>

				<Button text="Add Schedule" color="primary" onClick={_ => setAddAccountOpen(true)} />
				<AddSchedule serverId={serverId === undefined ? "" : serverId} setups={setups} open={addAccountOpen} onClose={() => { setAddAccountOpen(false); refreshData() }} />
			</div>

			<div>
				<Table>
					<TableHead className="bg-black bg-opacity-50 text-white">
						<TableHeadRow>
							<TableHeadCell>ID</TableHeadCell>
							<TableHeadCell>Setup ID</TableHeadCell>
							<TableHeadCell>Trigger Time</TableHeadCell>
							<TableHeadCell>Max Duration</TableHeadCell>
							<TableHeadCell>Days</TableHeadCell>
							<TableHeadCell></TableHeadCell>
						</TableHeadRow>
					</TableHead>
					<TableBody>
						{schedules.map((s, i) => <TableBodyRow marked={markedSetup === s.setup_id} onClick={() => setMarkedSetup(markedSetup && markedSetup === s.setup_id ? null : s.setup_id)} key={i}>
							<TableBodyCell>{s.id}</TableBodyCell>
							<TableBodyCell>{s.setup_id}</TableBodyCell>
							<TableBodyCell>{s.hour}:{s.minute < 10 ? `0${s.minute}` : s.minute}</TableBodyCell>
							<TableBodyCell>{formatDuration(moment.duration(s.max_duration, 'seconds'))}</TableBodyCell>
							<TableBodyCell>{showDays(s.days)}</TableBodyCell>
							<TableBodyCell>
								<MenuDropdown color={"icon"} expand="left" icon={<HiDotsCircleHorizontal className="w-5 h-5 text-indigo-700 transition duration-300 transform hover:scale-110 cursor-pointer" aria-hidden="true" />}>
									<div className="px-1 py-1 ">
										<MenuDropdownItem onClick={() => doDeleteSchedule(s.id)} text={"Delete"} icon={<HiTrash className="w-5 h-5 mr-2 text-red-700 opacity-80" />} />
									</div>
								</MenuDropdown>
							</TableBodyCell>
							
						</TableBodyRow>)}
					</TableBody>
				</Table>
			</div>


			<h2 className="font-bold text-2xl my-10">Runs</h2>

			<div>
				<Table>
					<TableHead className="bg-black bg-opacity-50 text-white">
						<TableHeadRow>
							<TableHeadCell>ID</TableHeadCell>
							<TableHeadCell>Schedule ID</TableHeadCell>
							<TableHeadCell>Setup ID</TableHeadCell>
							<TableHeadCell>Trigger Time</TableHeadCell>
							<TableHeadCell>Deadline</TableHeadCell>
							<TableHeadCell>Running</TableHeadCell>
						</TableHeadRow>
					</TableHead>
					<TableBody>
						{scheduledRuns.map((s, i) => <TableBodyRow onClick={() => setMarkedSetup(markedSetup && markedSetup === s.setup_id ? null : s.setup_id)} marked={markedSetup === s.schedule_id} key={i}>
							<TableBodyCell>{s.id}</TableBodyCell>
							<TableBodyCell>{s.schedule_id}</TableBodyCell>
							<TableBodyCell>{s.setup_id}</TableBodyCell>
							<TableBodyCell><span title={moment.unix(s.trigger_time).toLocaleString()}>{moment.unix(s.trigger_time).fromNow()}</span></TableBodyCell>
							<TableBodyCell><span title={moment.unix(s.deadline).toLocaleString()}>{moment.unix(s.deadline).fromNow()}</span></TableBodyCell>
							<TableBodyCell>{s.running ? "Yes" : "No"}</TableBodyCell>
						</TableBodyRow>)}
					</TableBody>
				</Table>
			</div>

			<h2 id="logs" ref={logsRef} className="font-bold text-2xl my-10">Logs</h2>

			<div >
				<Table>
					<TableHead className="bg-black bg-opacity-50 text-white">
						<TableHeadRow>
							<TableHeadCell>ID</TableHeadCell>
							<TableHeadCell>Setup ID</TableHeadCell>
							<TableHeadCell>Scheduled</TableHeadCell>
							<TableHeadCell>Error</TableHeadCell>
							<TableHeadCell>Start Time</TableHeadCell>
							<TableHeadCell>End Time</TableHeadCell>
							<TableHeadCell>Deadline</TableHeadCell>
							<TableHeadCell></TableHeadCell>
						</TableHeadRow>
					</TableHead>
					<TableBody>
						{logs.sort((a, b) => b.end_time - a.end_time).slice(0, MAX_LOGS).map((l, i) => <TableBodyRow onClick={() => setMarkedSetup(markedSetup && markedSetup === l.setup_id ? null : l.setup_id)} marked={markedSetup === l.setup_id} key={i}>
							<TableBodyCell>{l.id}</TableBodyCell>
							<TableBodyCell>{l.setup_id}</TableBodyCell>
							<TableBodyCell>{l.scheduled ? "Yes" : "No"}</TableBodyCell>
							<TableBodyCell>
								{l.has_error ? 
								<span onClick={() => doShowLog(l.id)}><BsBugFill className={`h-5 w-5 text-red-700 cursor-pointer`} /></span>
								 : "No"}
							 </TableBodyCell>
							<TableBodyCell><span title={moment.unix(l.start_time).toLocaleString()}>{moment.unix(l.start_time).fromNow()}</span></TableBodyCell>
							<TableBodyCell><span title={moment.unix(l.end_time).toLocaleString()}>{moment.unix(l.end_time).fromNow()}</span></TableBodyCell>
							<TableBodyCell><span title={moment.unix(l.deadline).toLocaleString()}>{moment.unix(l.deadline).fromNow()}</span></TableBodyCell>
							<TableBodyCell>
								<MenuDropdown color={"icon"} expand="left" icon={<HiDotsCircleHorizontal className="w-5 h-5 text-indigo-700 transition duration-300 transform hover:scale-110 cursor-pointer" aria-hidden="true" />}>
									<div className="px-1 py-1 ">
										<MenuDropdownItem onClick={() => doDownloadLog(l.id)} text={"Log"} icon={<BsCloudDownload className="w-5 h-5 mr-2 opacity-80" />} />
										<MenuDropdownItem onClick={() => doDownloadError(l.id)} text={"Error"} icon={<BsCloudDownload className="w-5 h-5 mr-2 opacity-80" />} />
										<MenuDropdownItem onClick={() => doDownloadStats(l.id)} text={"Stats"} icon={<BsCloudDownload className="w-5 h-5 mr-2 opacity-80" />} />
									</div>
								</MenuDropdown>
							</TableBodyCell>
						</TableBodyRow>)}
					</TableBody>
				</Table>
			</div>

		</div>

		<Dialog className="fixed z-10 inset-0 overflow-y-auto"
                open={openedError > -1}
                onClose={() => setOpenedError(-1)}>
            <div className="flex items-center justify-center min-h-screen">
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

                <div className="relative bg-white rounded mx-auto p-5 drop-shadow-lg">
					<Dialog.Title className="text-2xl">
                        Error details 
                    </Dialog.Title>
                    <br />
					{
						popupError ? 
							<div id="errorPopup" style={{whiteSpace: "pre-wrap", fontFamily: "courier"}}>
								{popupError}
							</div>
						: null
					}
					<br />
					
					<button className="bg-green-700 hover:bg-green-900 text-white font-bold py-2 px-4 rounded disabled:bg-green-300 disabled:hover:bg-green-300 mr-2"
						onClick={() => setOpenedError(-1)}>
						OK
					</button>
				</div>
			</div>
		</Dialog>
	</div >
}

export default APAOrganization