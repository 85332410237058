interface SelectProps {
    value: string | number;
    onChange(e: React.ChangeEvent<HTMLSelectElement>): any;
    label?: string;
}

const Select: React.FC<SelectProps> = ({ value, onChange, label, children}) => {
    return (
        <label className="block">
            <span className="text-gray-600 text-xs">{label}</span>
            <select value={value} onChange={onChange} className={`${!value ? "italic text-gray-500" : ""} block text-sm w-full mt-1 py-1.5 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50`}>
                {children}
            </select>
        </label>
    )
}

export default Select