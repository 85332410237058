import { useEffect, useState } from "react"
import { HiChevronRight } from "react-icons/hi"
import { get } from "../../helpers/Requests"
import { APAAccount, APASetup } from "../../types/types"

const APAAccountCard: React.FC<{ serverId: string, account: APAAccount, onClick(): any }> = ({ serverId, account, onClick }) => {
    const [setups, setSetups] = useState<APASetup[]>([])

    useEffect(() => {
        get(`apa/${serverId}/setups/account/${account.id}`)
            .then(setups => setSetups(setups))
            .catch(e => alert(e))
    }, [account.id, serverId])

    return <div onClick={onClick} className="flex flex-row justify-between items-center cursor-pointer first:rounded-t-md last:rounded-b-md hover:bg-neutral-50 py-3 px-6 last:border-0 border-b border-gray-300">
        <div className="flex flex-col gap-1 text-sm">
            <div className="font-semibold text-base text-sky-600">{account.name}</div>
        </div>
        <div className="flex flex-row-reverse items-center gap-8">
            <div><HiChevronRight className="h-5 w-5 text-gray-400" /></div>
            <div className="">
                <div className="flex flex-row items-center text-gray-500 gap-4 w-14">
                    <div className="font-semibold text-gray-500 border-r border-gray-300 pr-4">{setups.length}</div>
                    {setups.filter(s => s.quarantined).length > 0 ? <div className="font-semibold text-red-500">{setups.filter(s => s.quarantined).length}</div>
                    : <div className="font-semibold text-green-500">{0}</div>
                    }                   
                </div>
            </div>
        </div>
    </div>
}

export default APAAccountCard