import { Transition } from "@headlessui/react"
import { Fragment, useCallback, useState } from "react"
import { post } from "../../helpers/Requests"
import Button from "../Button"
import CustomInput from "../CustomInput"

const AddAccount: React.FC<{
    serverId: string,
    open: boolean,
    onClose(): any,
}> = ({ serverId, open, onClose }) => {

    const [name, setName] = useState<string>("")

    const onCloseLocal = useCallback(() => {
        onClose();
        setName('')
        setTimeout(() => {
        }, 300) // wait for aninmation to finish
    }, [onClose])

    const doCreateAccount = useCallback(() => {
        post(`apa/${serverId}/accounts`, { name: name })
            .then(res => {
                onCloseLocal()
            })
            .catch(err => alert(err))
    }, [name, serverId, onCloseLocal])

    return <Transition
        as={Fragment}
        show={open}
        enter="transform transition duration-300"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transform duration-200 transition ease-in-out"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95 "
    >
        <div className="fixed right-4 top-[220px] z-40 text-sm rounded-lg shadow-2xl border bg-white border-gray-300">
            <div className="w-[400px]">
                <div className="p-4 border-b border-gray-200">
                    <div className="font-semibold text-base">Add setup</div>
                </div>
                <div className="flex flex-col p-4 gap-2">
                    <div>
                        <CustomInput name="name" label="Name" value={name} onChange={e => setName(e.target.value)} />
                    </div>

                </div>
                <div className="bg-gray-50 border-t border-gray-300 rounded-br-lg rounded-bl-lg">
                    <div className="p-4 flex flex-row-reverse gap-2">
                        <div><Button disabled={name === ""} text="Save" color="primary" onClick={() => { doCreateAccount() }} /></div>
                        <div><Button text="Cancel" color="secondary" onClick={onCloseLocal} /></div>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
}

export default AddAccount