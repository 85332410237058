import { BsCheckLg, BsDashLg } from 'react-icons/bs'
import '../../css/CustomCheckbox.css'

interface CustomCheckboxProps{
    value: boolean
    onChange: any
    text?: string
    className?: any
    title?: string
    type?: string 
    disabled?: boolean
}

export default function CustomCheckbox({ value, onChange, text, className, title, type = "checked", disabled }: CustomCheckboxProps) {



    return (
        <div className={`checkbox ${className ? className : ''} ${disabled ? "disabled" : ""}`} aria-disabled={disabled} onClick={e => onChange(!value)} title={title} >
            <div className={`checkbox-box ${type === "prechecked" ? "prechecked" : ""} ${value && type==="checked" ? 'checkbox-box-checked' : ''}`}>
           
                  <BsCheckLg size={12} />
             
            </div>
            <h2 className={`${value ? 'checkbox-checked' : ''}`} >{text}</h2>
        </div>
    )
}