import { useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import AddAccount from "../components/APA/AddAccount"
import APAAccountCard from "../components/APA/APAAccountCard"
import Button from "../components/Button"
import { get } from "../helpers/Requests"
import { APAAccount } from "../types/types"
import { useDispatch } from "../contexts/StoreContext"

const APA = () => {
    const { serverId } = useParams()
    const [accounts, setAccounts] = useState<APAAccount[]>([])
    const [addAccount, setAddAccount] = useState<boolean>(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()



    const getACcounts = useCallback(() => {
                get('apa/' + serverId + '/accounts')
                    .then(accounts => setAccounts(accounts))
                    .catch(e => alert(e))
                }, [serverId])

    useEffect(() => {
        getACcounts()
        dispatch({ type: "set_accounts", accounts: accounts })

    }, [getACcounts])

    return <div className="bg-neutral-100 min-h-full pb-8">
        <div className="flex justify-between items-end mb-4">
            <div className="px-4 py-2"><h1 className="text-gray-500 text-lg font-semibold">APA Accounts</h1></div>
            <div className="px-4 py-2"><Button text="Add account" color="primary" onClick={() => setAddAccount(true)} /></div>
            <AddAccount serverId={serverId !== undefined ? serverId : ""} open={addAccount} onClose={() => { setAddAccount(false); getACcounts() }} />
        </div>

        <div className="mx-4 flex flex-row gap-4">
            <div className="flex-1 bg-white rounded-md shadow-md">
                {accounts.map((a, id) => <APAAccountCard key={id} account={a} serverId={serverId !== undefined ? serverId : ""} onClick={() => navigate(`/apa/${serverId}/${a.id}`)} />)}
            </div>
            <div className="flex-1"></div>
        </div>
    </div>;
}

export default APA