import { get } from "../helpers/Requests";
import { CloudError} from "../types/cloud_types";
import { APARun, ServerData } from "../types/types";

export const getLatestRunErrors = async function (servers: ServerData[],) {
    let error: any;
    let response: APARun[] = []
    for (const server of servers) {
        if (server.type === "apa") {
            await get('apa/' + server.id + 'setups/runs/latest')
                .then((data) => {
                    response = response.concat(data)
                })
                .catch(e => {
                    error = e;
            })
        }
        if (error) {
            break;
        }
    }
    return error ? Promise.reject(error) : Promise.resolve(response)        
}

export const getCloudErrors = async function (servers: ServerData[],) {
    let error: any;
    let response: CloudError[] = []
    for (const server of servers) {
        if (server.id && server.type == "cloud") {
            await get("cloud/" + server.id + "/errors")
                .then((errors) => {
                     response = response.concat(errors);
                })
                .catch(e => {
                    error = e;
                })
        }
        if (error) {
            break;
        }
    }
    return error ? Promise.reject(error) : Promise.resolve(response)

}