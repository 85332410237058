import { accountReducer } from "./accountReducers";
import { orgsReducer } from "./orgReducers";
import { latestRunReducer } from "./latestRunReducer";
import { serverReducer } from "./serverReducer";
import { Store } from "../contexts/StoreContext";


const mainReducer = (state: Store, action: any): Store => {
    return {
        orgs: orgsReducer(state, action),
        accounts: accountReducer(state, action),
        latest_runs: latestRunReducer(state, action),
        servers: serverReducer(state, action)
    };
};

export default mainReducer;

