import { CloudUser } from "../../types/cloud_types"
import { useEffect, useState } from "react"
import Button from "../Button"
import CustomRadioGroup from "../Buttons/CustomRadioGroup"
import CustomRadioOption from "../Buttons/CustomRadioOption"
import CustomCombobox from "../CustomCombobox"
import CustomInput from "../CustomInput"
import { AlertBannerProps } from "../Alerts/AlertBanner"
import Creatable from "react-select/creatable"

type EditUserInfoProps = {
    user: CloudUser,
    userHomeInfo: any,
    changeHandler: Function,
    cancelAlertHandler: Function,
    alertHandler: Function,
    sendWelcomeMail: Function,
    disableUser: Function,
    locations?: string[],
    locationFieldSettings: {location_field_mandatory:boolean, location_field_enabled: boolean},
}
export default function EditUserInfo({ user, userHomeInfo, changeHandler, cancelAlertHandler, alertHandler, sendWelcomeMail, disableUser, locations, locationFieldSettings }: EditUserInfoProps) {

    const departments = [
        { id: 1, value: "Procurement", label: "Procurement", },
        { id: 2, value: "Logistics", label: "Logistics/Supply Chain Management", },
        { id: 3, value: "Production", label: "Production", },
        { id: 4, value: "Category management", label: "Category management", },
        { id: 5, value: "Sourcing", label: "Sourcing", },
        { id: 6, value: "Sales", label: "Sales", },
        { id: 7, value: "Customer Service", label: "Customer Service", },
        { id: 8, value: "Finance", label: "Finance", },
        { id: 9, value: "IT", label: "IT", },
        { id: 10, value: "Management", label: "Management", },
        { id: 11, value: "Other", label: "Other", },
    ]

    const [department, setDepartment] = useState(() => getSelectedDepartment(user.department))
    const [userInfo, setUserInfo] = useState({ ...user })
    const locationOptions = locations?.map(l => {
        return {
            label: l, value: l
        }
    })

    function disableUserHandler() {
        disableUser()
    }

    function getSelectedDepartment(department: string) {

        const index = departments.findIndex(d => d.value === department);
        if (index > -1) {
            return departments[index];
        }
        else {
            return departments[0]
        }
    }

    function sendWelcomeMailHandler() {

        if (!userHomeInfo.last_welcome_mail?.send_time) {
            sendWelcomeMail()
        } else {
            let config: AlertBannerProps = {
                type: "warning",
                title: "User has already received this mail before",
                text: "Do you want to send it anyway?",
                closeText: "Cancel",
                onClose: () => cancelAlertHandler(),
                actionText: "Send Anyway",
                onAction: () => sendWelcomeMail()
            }

            alertHandler(config)
        }
    }

    function valueChangedHandler(value: any, key: string) {
        let u = !!userInfo ? { ...userInfo } : { ...user }

        u.id = userInfo.id;
        u.firstname = userInfo.firstname;
        u.lastname = userInfo.lastname;
        u.email = userInfo.email;
        u.job_title = userInfo.job_title;
        u.phone_number = userInfo.phone_number;
        u.department = department.value;
        u.language = !!userInfo.language ? userInfo.language : "English";
        u.organization_id = userInfo.organization_id;
        u.deleted = userInfo.deleted;
        u.location = userInfo.location ?? ""
        u = Object.assign(userInfo, u)

        const keys = Object.keys(userInfo)

        for (const k in keys) {
            if (keys[k] === key) {
                Object.defineProperty(u, key, { value: value })
                break;
            }
        }
        
        setUserInfo({...u})
        changeHandler(u)
    }

    return <div className="max-w-lg mx-auto">
        <section className="flex justify-between space-x-3 mb-3 ">
            <CustomInput name='firstName' label='First name' error={userInfo.firstname.length === 0 ? true : false} errorText="Please provide a first name" defaultValue={userInfo.firstname} onChange={e => valueChangedHandler(e.target.value, "firstname")} />
            <CustomInput name='lastName' label='Last name' error={userInfo.lastname.length === 0 ? true : false} errorText="Please provide a last name" defaultValue={userInfo.lastname} onChange={e => valueChangedHandler(e.target.value, "lastname")} />
        </section>
        <section className="flex justify-between space-x-3 mb-3 items-center">
            <CustomInput name='phoneNumber' label='Phone number' error={!userInfo.phone_number.match(/^\+?\d{1,3}[\s\-]?\d{3,14}$/g) && userInfo.phone_number !== null && userInfo.phone_number.length > 0} errorText="Please provide a valid phone number" defaultValue={userInfo.phone_number} onChange={e => valueChangedHandler(e.target.value, "phone_number")} />
            <CustomRadioGroup label="Language" labelClass={"flex flex-col text-xs w-full text-gray-600"} className="flex items-end w-full text-sm" value={!!userInfo && !!userInfo.language ? userInfo.language : "English"} onChange={(e: any) => valueChangedHandler(e, "language")}>
                <CustomRadioOption key={"eng"} value={"English"} text={"English"} ></CustomRadioOption>
                <CustomRadioOption key={"da"} value={"Danish"} text={"Danish"} ></CustomRadioOption>
            </CustomRadioGroup>
        </section>
        <section className="flex justify-between space-x-3 mb-3">
            <CustomInput name='jobTitle' label='Job title' defaultValue={userInfo.job_title} error={!userInfo.job_title} errorText={"Please provide a job title"} onChange={e => valueChangedHandler(e.target.value, "job_title")} />
        </section>
        <section className="flex justify-between space-x-3 mb-3">
            <CustomCombobox label="Department" showValue={true} options={departments} selectedOption={department} setSelectedOption={o => { valueChangedHandler(o.value, "department"); setDepartment(o) }} ></CustomCombobox>
            {locationFieldSettings.location_field_enabled && <label className="w-full">
                <span className="text-gray-600 text-xs">Location</span>
                <Creatable
                    defaultValue={user.location ? {label: user.location, value: user.location} : null}
                    options={locationOptions}
                    required={locationFieldSettings.location_field_mandatory}
                    onChange={(e, actionType) => {
                        if(e != null){
                            valueChangedHandler(e.value, "location")
                        }
                        if(actionType.action === "clear"){
                            valueChangedHandler("", "location")
                        }
                    }}
                    isClearable={true}
                    backspaceRemovesValue={true}
                    placeholder="Enter location"
                />
                {locationFieldSettings.location_field_mandatory && !user.location && <span className="text-red-500 text-sm">Please provide a location</span>}
            </label>}
        </section>
        <div className="flex flex-col max-w-fit">
            <Button className="m-2 bg-transparent text-dark-forest-900 font-normal hover:text-white  border-solid" onClick={sendWelcomeMailHandler} text={"Send welcome mail"} color="primary" />
            <Button text={"Disable User"} className="max-w-fit m-2 bg-transparent hover:bg-gray-100 hover:text-white border border-solid focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-red-600 text-red-600 font-normal" onClick={() => disableUserHandler()} color="alert" />
        </div>
    </div>
}