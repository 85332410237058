const Table: React.FC = ({ children }) => {
    return <div className="flex flex-col">
    <div className="-my-2 sm:-mx-3 lg:-mx-4">
        <div className="py-2 align-middle inline-block min-w-full sm:px-3 lg:px-4">
            <div className="rounded-lg border">
                <table className={`min-w-full divide-y divide-gray-200`}>
                    {children}
                </table>
            </div>
        </div>
    </div>
</div>
}

export default Table