export const isThisPermission = (have: any[], want: any[]) => {
    if (!Array.isArray(have) || !Array.isArray(want) || have.length !== want.length)
        return false

    let h = have.concat().sort()
    let w = want.concat().sort()

    for (let i = 0; i < h.length; i++) {
        if (h[i] !== w[i]) {
            return false
        }
    }

    return true
}


export const permissions = ['report', 'reportadmin', 'reportcreate', 'checklistsadmin', 'checklistscreate', 'checklists', 'itemaccess', 'dataadmin', 'download', 'orgadmin', "reportingserviceonly"]

export const commonNames = {
    'Admin': ['reportadmin', 'checklistsadmin', 'itemaccess'],
    'User': ['report', 'checklists', 'itemaccess'],
    'Checklist Creator': ['report', 'checklistsadmin', 'itemaccess'],
    'Checklist Receiver': ['checklists', 'itemaccess']
}


const hasOne = (permissions: string[], ...wanted: string[]) => {
    if (!permissions || !wanted) {
        return false
    }

    let found = false

    wanted.forEach(perm => {
        permissions.forEach(given => {
            if (given === perm) {
                found = true
            }
        })
    })

    return found
}

export const is = {
    dataAdmin: ['dataadmin'],
    reportAdmin: ['reportadmin'],
    checklistsAdmin: ['checklistsadmin'],
    reportUser: ['report', 'reportcreate', 'reportadmin'],
    reportCreator: ['reportadmin', 'reportcreate'],
    reportCreateOnly: ['reportcreate'],
    checklistsUser: ['checklists', 'checklistsadmin', 'checklistscreate'],
    checklistsCreator: ['checklistsadmin', 'checklistscreate'],
    itemAccessor: ['itemaccess'],
    reportOnly: ['report'],
    dashboardUser: ['dashboard'],
    dashboardAdmin: ['dashboardadmin'],
    downloadUser: ['download'],
    orgAdmin: ['orgadmin'],
    reportingServiceOnly: ["reportingserviceonly"]
}

export const canUseReports = (permissions: string[]) => {
    return hasOne(permissions, 'report', 'reportcreate', 'reportadmin')
}

export const canCreateReports = (permissions: string[]) => {
    return hasOne(permissions, 'reportadmin', 'reportcreate')
}

export const isChecklistAdmin = (permissions: string[]) => {
    return hasOne(permissions, 'checklistsadmin')
}

export const canUseChecklists = (permissions: string[]) => {
    return hasOne(permissions, 'checklists', 'checklistsadmin', 'checklistscreate')
}

export const canCreateChecklists = (permissions: string[]) => {
    return hasOne(permissions, 'checklistsadmin', 'checklistscreate')
}

export const canUseDownload = (permissions: string[]) => {
    return hasOne(permissions, 'download')
}

export const isDataAdmin = (permissions: string[]) => {
    return hasOne(permissions, 'dataadmin')
}

export const isReportCreateOnly = (permissions: string[]) => {
    return hasOne(permissions, 'reportcreate') && !hasOne(permissions, 'reportadmin')
}

export const isReportAdmin = (permissions: string[]) => {
    return hasOne(permissions, 'reportadmin')
}

export const isReportUserOnly = (permissions: string[]) => {
    return hasOne(permissions, 'report') && !hasOne(permissions, 'reportadmin')
}

export const hasItemAccess = (permissions: string[]) => {
    return hasOne(permissions, 'itemaccess')
}

export const canUseDashboards = (permissions: string[]) => {
    return hasOne(permissions, "dashboard", "dashboardadmin")
}

export const isDashboardAdmin = (permissions: Permissions[]) => {
    return hasOne(permissions, 'dashboardadmin')
}

export const simplifyMenu = (permissions: string[]) => {
    return hasOne(permissions, 'simple_menu')
}

export const isOrgAdmin = (permissions: string[]) => {
    return hasOne(permissions, "orgadmin")
}

// Roles and permissions for admin panel

export enum PermissionCheckbox {
    UNCHECKED = "UNCHECKED",
    CHECKED = "CHECKED",
    DISABLED = "DISABLED",
    PRECHECKED = "PRECHECKED"
}

export enum Permissions {
    REPORT_ADMIN = "reportadmin",
    REPORT_CREATE = "reportcreate",
    REPORT = "report",
    CHECKLISTS_ADMIN = "checklistsadmin",
    CHECKLISTS_CREATE = "checklistscreate",
    CHECKLISTS = "checklists",
    DASHBOARD = "dashboard",
    DASHBOARD_ADMIN = "dashboardadmin",
    DOWNLOAD = "download",
    ORGANIZATION_ADMIN = "orgadmin",
}
export interface IPermissions {
    [Permissions.REPORT_ADMIN]: PermissionCheckbox,
    [Permissions.REPORT_CREATE]: PermissionCheckbox,
    [Permissions.REPORT]: PermissionCheckbox,
    [Permissions.CHECKLISTS_ADMIN]: PermissionCheckbox,
    [Permissions.CHECKLISTS_CREATE]: PermissionCheckbox,
    [Permissions.CHECKLISTS]: PermissionCheckbox,
    [Permissions.DASHBOARD]: PermissionCheckbox,
    [Permissions.DASHBOARD_ADMIN]: PermissionCheckbox,
    [Permissions.DOWNLOAD]: PermissionCheckbox,
    [Permissions.ORGANIZATION_ADMIN]: PermissionCheckbox,
}

export enum PermissionActions {
    MODIFY = "modify",
    VIEW = "view",
    CREATE = "create"
}

const permissionChecked = new Map()
const permissionUnchecked = new Map()

// report mappings
permissionChecked.set(
    Permissions.REPORT_ADMIN,
    {
        [Permissions.REPORT_ADMIN]: PermissionCheckbox.CHECKED,
        [Permissions.REPORT_CREATE]: PermissionCheckbox.PRECHECKED,
        [Permissions.REPORT]: PermissionCheckbox.PRECHECKED
    })

permissionChecked.set(
    Permissions.REPORT_CREATE,
    {
        [Permissions.REPORT_ADMIN]: PermissionCheckbox.UNCHECKED,
        [Permissions.REPORT_CREATE]: PermissionCheckbox.CHECKED,
        [Permissions.REPORT]: PermissionCheckbox.PRECHECKED
    })

permissionChecked.set(
    Permissions.REPORT,
    {
        [Permissions.REPORT_ADMIN]: PermissionCheckbox.UNCHECKED,
        [Permissions.REPORT_CREATE]: PermissionCheckbox.UNCHECKED,
        [Permissions.REPORT]: PermissionCheckbox.CHECKED
    })

permissionUnchecked.set(
    Permissions.REPORT,
    {
        [Permissions.REPORT_ADMIN]: PermissionCheckbox.UNCHECKED,
        [Permissions.REPORT_CREATE]: PermissionCheckbox.UNCHECKED,
        [Permissions.REPORT]: PermissionCheckbox.UNCHECKED
    })

permissionUnchecked.set(
    Permissions.REPORT_ADMIN,
    { ...permissionChecked.get(Permissions.REPORT_CREATE) })

permissionUnchecked.set(
    Permissions.REPORT_CREATE,
    { ...permissionChecked.get(Permissions.REPORT) })

// checklist mappings
permissionChecked.set(
    Permissions.CHECKLISTS_ADMIN,
    {
        [Permissions.CHECKLISTS_ADMIN]: PermissionCheckbox.CHECKED,
        [Permissions.CHECKLISTS_CREATE]: PermissionCheckbox.PRECHECKED,
        [Permissions.CHECKLISTS]: PermissionCheckbox.PRECHECKED
    })

permissionChecked.set(
    Permissions.CHECKLISTS_CREATE,
    {
        [Permissions.CHECKLISTS_ADMIN]: PermissionCheckbox.UNCHECKED,
        [Permissions.CHECKLISTS_CREATE]: PermissionCheckbox.CHECKED,
        [Permissions.CHECKLISTS]: PermissionCheckbox.PRECHECKED
    })

permissionChecked.set(
    Permissions.CHECKLISTS,
    {
        [Permissions.CHECKLISTS_ADMIN]: PermissionCheckbox.UNCHECKED,
        [Permissions.CHECKLISTS_CREATE]: PermissionCheckbox.UNCHECKED,
        [Permissions.CHECKLISTS]: PermissionCheckbox.CHECKED
    })

permissionUnchecked.set(
    Permissions.CHECKLISTS_ADMIN,
    { ...permissionChecked.get(Permissions.CHECKLISTS_CREATE) })
permissionUnchecked.set(
    Permissions.CHECKLISTS_CREATE,
    { ...permissionChecked.get(Permissions.CHECKLISTS) })
permissionUnchecked.set(
    Permissions.CHECKLISTS,
    {
        [Permissions.CHECKLISTS_ADMIN]: PermissionCheckbox.UNCHECKED,
        [Permissions.CHECKLISTS_CREATE]: PermissionCheckbox.UNCHECKED,
        [Permissions.CHECKLISTS]: PermissionCheckbox.UNCHECKED
    })

permissionChecked.set(
    "simple_report",
    {
        [Permissions.REPORT_ADMIN]: PermissionCheckbox.DISABLED,
        [Permissions.REPORT_CREATE]: PermissionCheckbox.DISABLED,
        [Permissions.REPORT]: PermissionCheckbox.CHECKED
    })
permissionUnchecked.set(
    "simple_report",
    {
        [Permissions.REPORT_ADMIN]: PermissionCheckbox.DISABLED,
        [Permissions.REPORT_CREATE]: PermissionCheckbox.DISABLED,
        [Permissions.REPORT]: PermissionCheckbox.UNCHECKED
    })

permissionChecked.set(
    "simple_checklists",
    {
        [Permissions.CHECKLISTS_ADMIN]: PermissionCheckbox.DISABLED,
        [Permissions.CHECKLISTS_CREATE]: PermissionCheckbox.DISABLED,
        [Permissions.CHECKLISTS]: PermissionCheckbox.CHECKED
    }
)
permissionUnchecked.set(
    "simple_checklists",
    {
        [Permissions.CHECKLISTS_ADMIN]: PermissionCheckbox.DISABLED,
        [Permissions.CHECKLISTS_CREATE]: PermissionCheckbox.DISABLED,
        [Permissions.CHECKLISTS]: PermissionCheckbox.UNCHECKED
    }
)
permissionUnchecked.set(
    "simple_download",
    {
        [Permissions.DOWNLOAD]: PermissionCheckbox.DISABLED,

    }
)
permissionUnchecked.set(
    "simple_orgadmin",
    {
        [Permissions.ORGANIZATION_ADMIN]: PermissionCheckbox.DISABLED,
    }
)

// download mappings

permissionChecked.set(
    Permissions.DOWNLOAD,
    {
        [Permissions.DOWNLOAD]: PermissionCheckbox.CHECKED,
    })
permissionUnchecked.set(
    Permissions.DOWNLOAD,
    {
        [Permissions.DOWNLOAD]: PermissionCheckbox.UNCHECKED,
    })

// dashboard mappings
permissionChecked.set(
    Permissions.DASHBOARD,
    {
        [Permissions.DASHBOARD_ADMIN]: PermissionCheckbox.UNCHECKED,
        [Permissions.DASHBOARD]: PermissionCheckbox.CHECKED,
    })
permissionChecked.set(
    Permissions.DASHBOARD_ADMIN,
    {
        [Permissions.DASHBOARD_ADMIN]: PermissionCheckbox.CHECKED,
        [Permissions.DASHBOARD]: PermissionCheckbox.PRECHECKED,
    })
permissionUnchecked.set(
    Permissions.DASHBOARD_ADMIN,
    { ...permissionChecked.get(Permissions.DASHBOARD) })

permissionUnchecked.set(
    Permissions.DASHBOARD,
    {
        [Permissions.DASHBOARD_ADMIN]: PermissionCheckbox.UNCHECKED,
        [Permissions.DASHBOARD]: PermissionCheckbox.UNCHECKED,
    })

permissionChecked.set(
    "simple_dashboard",
    {
        [Permissions.DASHBOARD_ADMIN]: PermissionCheckbox.DISABLED,
        [Permissions.DASHBOARD]: PermissionCheckbox.CHECKED
    }
)
permissionUnchecked.set(
    "simple_dashboard",
    {
        [Permissions.DASHBOARD_ADMIN]: PermissionCheckbox.DISABLED,
        [Permissions.DASHBOARD]: PermissionCheckbox.UNCHECKED
    }
)

// org admin mapping

permissionChecked.set(
    Permissions.ORGANIZATION_ADMIN,
    {
        [Permissions.ORGANIZATION_ADMIN]: PermissionCheckbox.CHECKED,
    })
permissionUnchecked.set(
    Permissions.ORGANIZATION_ADMIN,
    {
        [Permissions.ORGANIZATION_ADMIN]: PermissionCheckbox.UNCHECKED,
    })

export interface IPermissionSettings {
    [index: string]: PermissionActions[] | boolean,
    report: PermissionActions[],
    checklists: PermissionActions[],
    download: boolean,
    dashboard: PermissionActions[]
    organization: boolean,
}

const admin: IPermissions = {
    ...permissionChecked.get(Permissions.REPORT_ADMIN),
    ...permissionChecked.get(Permissions.CHECKLISTS_ADMIN),
    ...permissionChecked.get(Permissions.DASHBOARD_ADMIN),
    ...permissionChecked.get(Permissions.DOWNLOAD),
    ...permissionUnchecked.get(Permissions.ORGANIZATION_ADMIN)
}
const admin2: IPermissions = {
    ...permissionChecked.get(Permissions.REPORT_ADMIN),
    ...permissionChecked.get(Permissions.CHECKLISTS_ADMIN),
    ...permissionChecked.get(Permissions.DASHBOARD_ADMIN),
    ...permissionChecked.get(Permissions.DOWNLOAD),
    ...permissionChecked.get(Permissions.ORGANIZATION_ADMIN)
}


const super_user: IPermissions = {
    ...permissionChecked.get(Permissions.REPORT_CREATE),
    ...permissionChecked.get(Permissions.CHECKLISTS_CREATE),
    ...permissionChecked.get(Permissions.DASHBOARD),
    ...permissionChecked.get(Permissions.DOWNLOAD),
    ...permissionUnchecked.get(Permissions.ORGANIZATION_ADMIN)
}
const user: IPermissions = {
    ...permissionChecked.get(Permissions.REPORT),
    ...permissionChecked.get(Permissions.CHECKLISTS),
    ...permissionChecked.get(Permissions.DASHBOARD),
    ...permissionUnchecked.get(Permissions.DOWNLOAD),
    ...permissionUnchecked.get(Permissions.ORGANIZATION_ADMIN)
}

const empty: IPermissions = {
    ...permissionUnchecked.get(Permissions.REPORT),
    ...permissionUnchecked.get(Permissions.CHECKLISTS),
    ...permissionUnchecked.get(Permissions.DASHBOARD),
    ...permissionUnchecked.get(Permissions.DOWNLOAD),
    ...permissionUnchecked.get(Permissions.ORGANIZATION_ADMIN)
}

const simple_menu: IPermissions = {
    ...permissionChecked.get("simple_report"),
    ...permissionChecked.get("simple_checklists"),
    ...permissionChecked.get("simple_dashboard"),
    ...permissionUnchecked.get("simple_download"),
    ...permissionUnchecked.get("simple_orgadmin")
}
const default_view: IPermissions = {
    ...permissionChecked.get(Permissions.REPORT),
    ...permissionChecked.get(Permissions.CHECKLISTS),
    ...permissionChecked.get(Permissions.DASHBOARD),
    ...permissionUnchecked.get(Permissions.DOWNLOAD),
}


export interface IRoleSettings {
    [key: string]: IPermissions,
}
const defaultRoles = ["admin", "admin2", "super_user", "user", "reporting_only"]
export const defaultRolesSettings: IRoleSettings[] = [
    { "admin": admin },
    { "admin2": admin2 },
    { "super_user": super_user },
    { "user": user },
    { "reporting_only": empty }
]

export const mapRoletoPermissions = (role: string, permissionSettings?: IRoleSettings[]): IPermissions => {

    let mappedRole = user;
    if (defaultRoles.includes(role)) {
        switch (role) {
            case "admin":
                mappedRole = admin;
                break;
            case "admin2":
                mappedRole = admin2;
                break;
            case "user":
                mappedRole = user
                break;
            case "super_user":
                mappedRole = super_user
                break;
            case "simple_menu":
                mappedRole = simple_menu
                break;
            case "reporting_only":
                mappedRole = empty
                break;
            default:
                mappedRole = user
                break;
        }
    }
    return mappedRole;
}


export const comparePermissionsToRoles = (permissions: IPermissions, customRoles: IRoleSettings[] = []) => {
    let roles: IRoleSettings[] = [];
    let role: string = "custom";
    if (!!customRoles.length) {
        roles = defaultRolesSettings.concat(customRoles);
    } else {
        roles = defaultRolesSettings;
    }

    for (const r of roles) {

        const key = Object.keys(r)[0];
        const existingRole = r[key];

        const isSame = isPermissionsTheSame(existingRole, permissions)

        if (isSame) {
            role = Object.keys(r)[0];
        }
    }

    if (role === "admin2") {
        role = "admin"
    }

    return { [role]: permissions };
}

function isPermissionsTheSame(object1: IPermissions, object2: IPermissions) {
    const entries1 = Object.entries(object1);
    const entries2 = Object.entries(object2);
    if (entries1.length !== entries2.length) {
        return false;
    }
    for (let [key, value] of entries1) {

        const index = entries1.findIndex((e) => {
            return e[0] === key && e[1] === value;
        })

        let entries2Key = entries2[index][0];
        let entries2Value = entries2[index][1];
        if (entries2Value === PermissionCheckbox.DISABLED) {
            entries2Value = PermissionCheckbox.UNCHECKED;
        }
        if (key !== entries2Key || value !== entries2Value) {
            return false;
        }
    }
    return true;
}


export const getConditionalSimpleView = (perms: IPermissions) => {
    let newPerms = { ...simple_menu };

    let check = PermissionCheckbox;
    if (perms[Permissions.REPORT] === check.UNCHECKED) {
        newPerms = Object.assign(newPerms, permissionUnchecked.get("simple_report"));
    }

    if (perms[Permissions.CHECKLISTS] === check.UNCHECKED) {
        newPerms = Object.assign(newPerms, permissionUnchecked.get("simple_checklists"));
    }

    if (perms[Permissions.DASHBOARD] === check.UNCHECKED) {
        newPerms = Object.assign(newPerms, permissionUnchecked.get("simple_dashboard"));

    }

    return newPerms;
}

export const getConditionalPermissions = (prevPerms: IPermissions, changedValue: Permissions, simple_view: boolean = false) => {
    const prevState = prevPerms[changedValue]

    let value = changedValue;

    if (prevState === PermissionCheckbox.DISABLED) {
        return prevPerms;
    }
    // what the state will be when the user has clicked
    const checked = prevState === PermissionCheckbox.CHECKED ? false : true;


    switch (true) {
        case checked && simple_view:
            if (value === "report") {
                return Object.assign({}, prevPerms, permissionChecked.get("simple_report"))
            } else if (value === "checklists") {
                return Object.assign({}, prevPerms, permissionChecked.get("simple_checklists"))
            } else if (value === "dashboard") {
                return Object.assign({}, prevPerms, permissionChecked.get("simple_dashboard"))
            } else {
                return Object.assign({}, prevPerms, permissionChecked.get(value))
            }
        case !checked && simple_view:
            if (value === "report") {
                return Object.assign({}, prevPerms, permissionUnchecked.get("simple_report"))
            } else if (value === "checklists") {
                return Object.assign({}, prevPerms, permissionUnchecked.get("simple_checklists"))
            } else if (value === "dashboard") {
                return Object.assign({}, prevPerms, permissionUnchecked.get("simple_dashboard"))
            } else {
                return Object.assign({}, prevPerms, permissionUnchecked.get(value))
            }
        case checked && !simple_view:
            return Object.assign({}, prevPerms, permissionChecked.get(value))
        case !checked && !simple_view:
            return Object.assign({}, prevPerms, permissionUnchecked.get(value))
    }

}

export const mapPermChecksToPermList = (permissions: IPermissions): string[] => {
    const perms: [string, PermissionCheckbox][] = Object.entries(permissions)

    const permList: string[] = [];

    for (const perm of perms) {

        if (perm[1] === PermissionCheckbox.CHECKED || perm[1] === PermissionCheckbox.PRECHECKED) {
            permList.push(perm[0])
        }
    }
    return permList;
}

export const mapPermListToPermChecks = (permissions: string[]): IPermissions => {
    let permChecks: IPermissions = empty;
    const isSimpleView = permissions?.includes("simple_menu")
    if (!permissions) {
        return permChecks
    }
    if (isSimpleView) {

        if (permissions.includes(Permissions.REPORT)) {
            permChecks = {
                ...permChecks,
                ...permissionChecked.get("simple_report")
            }
        } else {
            permChecks = {
                ...permChecks,
                ...permissionUnchecked.get("simple_report")
            }
        }

        if (permissions.includes(Permissions.CHECKLISTS)) {
            permChecks = {
                ...permChecks,
                ...permissionChecked.get("simple_checklists")
            }
        } else {
            permChecks = {
                ...permChecks,
                ...permissionUnchecked.get("simple_checklists")
            }
        }
        if (permissions.includes(Permissions.DASHBOARD)) {
            permChecks = {
                ...permChecks,
                ...permissionChecked.get("simple_dashboard")
            }
        } else {
            permChecks = {
                ...permChecks,
                ...permissionUnchecked.get("simple_dashboard")
            }
        }

        permChecks = {
            ...permChecks,
            ...permissionUnchecked.get("simple_download")
        }
        permChecks = {
            ...permChecks,
            ...permissionUnchecked.get("simple_orgadmin")
        }

    } else {
        if (permissions?.includes(Permissions.REPORT_ADMIN)) {
            permChecks = {
                ...permChecks,
                ...permissionChecked.get(Permissions.REPORT_ADMIN)
            }

        } else if (permissions.includes(Permissions.REPORT_CREATE)) {
            permChecks = {
                ...permChecks,
                ...permissionChecked.get(Permissions.REPORT_CREATE)
            }

        } else if (permissions.includes(Permissions.REPORT)) {
            permChecks = {
                ...permChecks,
                ...permissionChecked.get(Permissions.REPORT)
            }
        }

        if (permissions.includes(Permissions.CHECKLISTS_ADMIN)) {
            permChecks = {
                ...permChecks,
                ...permissionChecked.get(Permissions.CHECKLISTS_ADMIN)
            }

        } else if (permissions.includes(Permissions.CHECKLISTS_CREATE)) {

            permChecks = {
                ...permChecks,
                ...permissionChecked.get(Permissions.CHECKLISTS_CREATE)
            }
        } else if (permissions.includes(Permissions.CHECKLISTS)) {
            permChecks = {
                ...permChecks,
                ...permissionChecked.get(Permissions.CHECKLISTS)
            }
        }

        if (permissions.includes(Permissions.DASHBOARD_ADMIN)) {
            permChecks = {
                ...permChecks,
                ...permissionChecked.get(Permissions.DASHBOARD_ADMIN)
            }
        } else if (permissions.includes(Permissions.DASHBOARD)) {
            permChecks = {
                ...permChecks,
                ...permissionChecked.get(Permissions.DASHBOARD)
            }
        } else {
            permChecks = {
                ...permChecks,
                ...permissionUnchecked.get(Permissions.DASHBOARD),
            }
        }

        if (permissions.includes(Permissions.DOWNLOAD)) {
            permChecks = {
                ...permChecks,
                ...permissionChecked.get(Permissions.DOWNLOAD)
            }
        } else {
            permChecks = {
                ...permChecks,
                ...permissionUnchecked.get(Permissions.DOWNLOAD)
            }
        }

        if (permissions.includes(Permissions.ORGANIZATION_ADMIN)) {
            permChecks = {
                ...permChecks,
                ...permissionChecked.get(Permissions.ORGANIZATION_ADMIN)
            }
        } else {
            permChecks = {
                ...permChecks,
                ...permissionUnchecked.get(Permissions.ORGANIZATION_ADMIN)
            }
        }
    }

    return permChecks

}





