import { ReactNode, useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { get, post } from "../helpers/Requests"
import { CloudOrgWithReporting, ReportingItem } from "../types/reporting_types"
import { ReportingItemStats } from "../components/Reporting/ReportingItem";
import { getReportingStats } from "../helpers/reporting_helpers";
import ReportingDashboards from "./ReportingDashboards";
import ReportingDashboardTables from "./ReportingDashboardTables";
import ReportingReports from "./ReportingReports";
import ReportingTrendViews from "./ReportingTrendViews";
import { CloudUser } from "../types/cloud_types";
import {Plan} from "../types/reporting_types";
import TabBar from "../components/TabBar"
import { useSideBarMenuContext } from "../components/Sidebar/SideBarContext";
import { ServerData} from "../types/types"

type ReportingOrgProps = {
    serverId?: number,
    orgId?:number
}
const ReportingOrganization = (props:ReportingOrgProps) => {
    
    let { serverId, orgId } = useParams()

    if(!serverId && props.serverId) {
        serverId = props.serverId.toString();
    }

    if(!orgId && props.orgId) {
        orgId = props.orgId.toString()
    }
    const { setUp } = useSideBarMenuContext()

    const [server, setServer] = useState<ServerData>()
    const [tab, setTab] = useState(0)
    const [cloudOrganization, setCloudOrganization] = useState<CloudOrgWithReporting>();
    const [cloudUsers, setCloudUsers] = useState<CloudUser[]>([])

    useEffect(() => {
        get(`servers/${serverId}`)
        .then(setServer)
        .catch(err => {
            console.log(err)
        })
    }, [serverId])
    
    const getCloudOrgWithReporting = useCallback(async (): Promise<CloudOrgWithReporting> => {
        if(server?.cloud && serverId){
            try {
                // getting orgs from cloud, and dashboards and reports from reporting
                const [orgs, dashboards, dashboardtables, trendviews, reports, plans] = 
                    await Promise.all([get(`cloud/${server?.cloud}/organizations`), get(`reporting/${serverId}/dashboard`), get(`reporting/${serverId}/dashtable`), get(`reporting/${serverId}/trendview`), get(`reporting/${serverId}/report`), get(`reporting/${serverId}/plan`)])
                // Finding the org with the orgId from the url
                const org = orgs.find((o: any) => o.id === parseInt(orgId ?? "-1"))
                // Getting only dashboards and reports that belong to the org
                const org_dashboards = dashboards.filter((d: any) => org.id === d.org_id)
                const org_reports = reports.filter((r: any) => org.id === r.org_id)
                const org_dashtables = dashboardtables.filter((r: any) => org.id === r.org_id)
                const org_trendviews = trendviews.filter((r: any) => org.id === r.org_id)

                const sorted_plans =  [...plans].sort((a, b) => a.description > b.description ? 1 : -1)

                return { id: org.id, name: org.name, deleted: org.deleted, dashboards: org_dashboards, dashboardtables: org_dashtables, trendviews: org_trendviews, reports: org_reports, plans: sorted_plans }
            } catch (e) {
                console.log(e)
                return { id: -1, name: "", deleted: false, dashboards: [], dashboardtables: [], trendviews: [], reports: [], plans: [] }
            }
        }
        else {
            return { id: -1, name: "", deleted: false, dashboards: [], dashboardtables: [], trendviews: [], reports: [], plans: [] }
        }
    }, [server, orgId, serverId])

    useEffect(() => {
        setUp("/reporting")
        async function fetch() {
            const org = await getCloudOrgWithReporting()
            const users = await get(`cloud/${server?.cloud}/organizations/${orgId}/users`) // users are used when creating/editting a reporting dashboard/report with easy dropdown
            setCloudOrganization(org)
            setCloudUsers(users)
        }
        if(server?.cloud) {
            fetch()
        }
    }, [orgId, setUp, server, getCloudOrgWithReporting])

    const sendMail = (id: number, email: string, test: boolean) => {
        let body = {id: id, email: email}

        let url = test ? `reporting/${serverId}/emailTest` : `reporting/${serverId}/emailReal`

        if(window.confirm("Are you sure you want to send a " + ( test ? "test" : "REAL" ) + " e-mail")){
            post(url, body).then(res => {
                alert('Email queued')
                update()
                
            }).catch(err => {console.log(err); alert('Failed to send')})
        }
    }

    const update = async () => {
        // Updates local state after every CRUD operation to DB
        try {
            const org = await getCloudOrgWithReporting()
            setCloudOrganization(org)
        } catch(e) {
            console.log(e)
        }
    }

    return <div className="bg-gray-100 h-full">
        <div className="bg-white p-8 border-b border-gray-200">
            <h1 className="font-bold text-3xl mb-10">Settings</h1>
            <TabBar selected={tab} tabs={["Dashboards", "Dashboard tables", "Trend views", "Reports"]} setTab={setTab} 
                    labels={[(cloudOrganization?.dashboards?.length ?? 0), (cloudOrganization?.dashboardtables?.length ?? 0), 
                             (cloudOrganization?.trendviews?.length ?? 0), (cloudOrganization?.reports?.length ?? 0)]} 
            />
            <div>
                {tab === 0 && <ReportingDashboards server={server} update={update} sendmail={sendMail} reporting_dashboards={cloudOrganization?.dashboards ?? []} plans={cloudOrganization?.plans ?? []} users={cloudUsers} />}
                {tab === 1 && <ReportingDashboardTables server={server} update={update} sendmail={sendMail} reporting_dashboards={cloudOrganization?.dashboardtables ?? []} plans={cloudOrganization?.plans ?? []} users={cloudUsers} />}
                {tab === 2 && <ReportingTrendViews server={server} update={update} sendmail={sendMail} reporting_reports={cloudOrganization?.trendviews ?? []} plans={cloudOrganization?.plans ?? []} users={cloudUsers} />}
                {tab === 3 && <ReportingReports server={server} update={update} sendmail={sendMail} reporting_reports={cloudOrganization?.reports ?? []}  plans={cloudOrganization?.plans ?? []} users={cloudUsers} />}
            </div>
        </div>
    </div>
}

export const ReportingOrgItem: React.FC<{ items: ReportingItem[], plans: Plan[], textColor: string, bgColor: string, icon: ReactNode }> = ({ items, plans, textColor, bgColor, icon }) => {
    return <div className="flex flex-row items-center justify-between gap-16">
        <div className="flex flex-row gap-6">
            <ReportingItemStats color={textColor} count={items.length} stats={getReportingStats(items, plans)} />
        </div>
    </div>
}

export default ReportingOrganization