import { ReportingItem, Plan } from "../../types/reporting_types"
import Table from "../Table/Table"
import TableBody from "../Table/TableBody"
import TableBodyCell from "../Table/TableBodyCell"
import TableHead from "../Table/TableHead"
import TableHeadCell from "../Table/TableHeadCell"
import TableBodyRow from "../Table/TableBodyRow"
import TableHeadRow from "../Table/TableHeadRow"
import MenuDropdown from "../MenuDropdown"
import MenuDropdownItem from "../MenuDropdownItem"
import { HiDotsCircleHorizontal, HiPencil, HiDuplicate, HiTrash, HiArrowSmUp, HiArrowSmDown } from "react-icons/hi"
import { useMemo, useState } from "react"
import { CloudDashboardObj, CloudReportObj } from "../../types/cloud_types"
import { FaCheckCircle, FaTimesCircle, FaPlayCircle } from "react-icons/fa";

const ReportingTable: React.FC<
    {
        items: ReportingItem[],
        plans: Plan[],
        cloudObj: CloudDashboardObj | CloudReportObj,
        onEdit(item: ReportingItem): any,
        onDelete(id: number): any,
        onDuplicate(item: ReportingItem): any,
        onToggleActive(item: ReportingItem): any,
        onSendMail(item: ReportingItem, test: boolean): any,
        color: "dashboard" | "dashboardtable" | "trendview" | "report",
    }
> = ({ items, plans, onEdit, onDelete, onDuplicate, onToggleActive, onSendMail, color, cloudObj }) => {
    const [sortedProp, setSortedProp] = useState("");
    const [direction, setDirection] = useState(false); // false = asc, true = desc

    const sortedItems = useMemo(() => items.slice().sort((a, b) => {
        if (typeof a[sortedProp] === "string") {
            const x = a[sortedProp].toLowerCase();
            const y = b[sortedProp].toLowerCase();
            if (!direction) {
                return x > y ? 1 : x < y ? -1 : 0
            } else {
                return x < y ? 1 : x > y ? -1 : 0;
            }
        } else {
            if (!direction) {
                return a[sortedProp] > b[sortedProp] ? 1 : a[sortedProp] < b[sortedProp] ? -1 : 0
            } else {
                return a[sortedProp] < b[sortedProp] ? 1 : a[sortedProp] > b[sortedProp] ? -1 : 0

            }
        }
    }), [items, sortedProp, direction]);

    const setSorting = (prop: string) => {
        if (sortedProp === prop && direction) {
            setSorting("")
        } else if (sortedProp === prop) {
            setDirection(!direction)
        } else {
            setSortedProp(prop)
            setDirection(false)
        }
    }

    const formatDate = (timestamp: number): string[] => {
        const time = new Date(timestamp * 1000).toLocaleString('da-DK', { timeStyle: "short" })
        const date = new Date(timestamp * 1000).toLocaleDateString('da-DK', { timeZone: 'Europe/Copenhagen', year: 'numeric', month: '2-digit', day: '2-digit' })
        return [date.replaceAll('.', '-'), time.replaceAll('.', ':')]
    }

    const colorObj: { headerBg: string, headerBgHover: string } = useMemo(() => {
        if (color === "dashboard") return { headerBg: "bg-blue-400 bg-opacity-75 cursor-pointer", headerBgHover: "hover:bg-blue-400 hover:text-gray-900 bg-opacity-100" };
        if (color === "report") return { headerBg: "bg-green-400 bg-opacity-75 cursor-pointer", headerBgHover: "hover:bg-green-400 hover:text-gray-900 bg-opacity-100" };
        if (color === "dashboardtable") return { headerBg: "bg-yellow-400 bg-opacity-75 cursor-pointer", headerBgHover: "hover:bg-yellow-400 hover:text-gray-900 bg-opacity-100" };
        if (color === "trendview") return { headerBg: "bg-red-400 bg-opacity-75 cursor-pointer", headerBgHover: "hover:bg-red-400 hover:text-gray-900 bg-opacity-100" };
        return { headerBg: "", headerBgHover: "" };
    }, [color]);

    const getPlan = (plan_id: number): string => {
        var plan = plans.find(x => x.id === plan_id);
        if(plan !== undefined)
            return plan.description
        else
            return "??"
    }

    const headers: { header: string, propName: string }[] = [{ header: "ID", propName: "id" }, { header: "Dashboard Name", propName: "dashboard_id" }, { header: "Report Name", propName: "report_id" }, { header: "Email", propName: "email" }, { header: "Firstname", propName: "firstname" }, { header: "Lastname", propName: "lastname" }, { header: "Plan", propName: "plan" }, { header: "Last sent", propName: "last_sent" }, { header: "Link", propName: "link" }, {header: "Include table", propName: "include_table"}]
    const dashObj = cloudObj as CloudDashboardObj;
    const repObj = cloudObj as CloudReportObj;

    return <Table>
        <TableHead className={`rounded-table ${colorObj.headerBg}`}>
            <TableHeadRow className="text-gray-700">
                {headers.map((h, i) => items[0] && items[0][h.propName] !== undefined &&
                    <TableHeadCell key={i} className={`${colorObj.headerBgHover} select-none`} onClick={() => setSorting(h.propName)}>
                        <div className="flex flex-row items-center">
                            <div className="mr-1">{h.header}</div>
                            <div className="w-5 h-5">
                                {h.propName === sortedProp && direction && <HiArrowSmUp className="h-5 w-5 text-gray-700" />}
                                {h.propName === sortedProp && !direction && <HiArrowSmDown className="h-5 w-5 text-gray-700" />}
                            </div>
                        </div>
                    </TableHeadCell>)}
                {items[0]?.dashboard_id && <TableHeadCell className={`cursor-default`}></TableHeadCell>}
                {items[0]?.report_id && <TableHeadCell className={`cursor-default`}></TableHeadCell>}
            </TableHeadRow>
        </TableHead>
        <TableBody className="rounded-table text-sm">
            {sortedItems.map((item, i) => <TableBodyRow className="" key={i}>
                <TableBodyCell>{item.id}</TableBodyCell>
                {item.dashboard_id && <TableBodyCell>{dashObj[item.dashboard_id]?.title ?? "No name"}</TableBodyCell>}
                {item.report_id && <TableBodyCell>{repObj[item.report_id]?.name ?? "No name"}</TableBodyCell>}
                <TableBodyCell>{item.email}</TableBodyCell>
                <TableBodyCell>{item.firstname}</TableBodyCell>
                <TableBodyCell>{item.lastname}</TableBodyCell>
                {/* <TableBodyCell>{item.email_text}</TableBodyCell>
                <TableBodyCell>{item.email_title}</TableBodyCell> */}
                {item.plan_id && <TableBodyCell>{getPlan(item.plan_id)}</TableBodyCell>}
                <TableBodyCell>{formatDate(item.last_sent)[0]} <span className="text-gray-500">{formatDate(item.last_sent)[1]}</span></TableBodyCell>
                <TableBodyCell>{item.link && <span>Yes</span>}{!item.link && <span className="text-gray-400">No</span>}</TableBodyCell>
                {item.include_table !== undefined && <TableBodyCell>{item.include_table && <span>Yes</span>}{!item.include_table && <span className="text-gray-400">No</span>}</TableBodyCell>}
                <TableBodyCell className="text-right">
                    <div style={{float:"left"}}>
                        <MenuDropdown color={"icon"} expand="left" icon={<HiDotsCircleHorizontal className="w-5 h-5 text-indigo-700 transition duration-300 transform hover:scale-110 cursor-pointer" aria-hidden="true" />}>
                            <div className="px-1 py-1 ">
                                <MenuDropdownItem onClick={() => onEdit(item)} text={"Edit"} icon={<HiPencil className="w-5 h-5 mr-2 text-gray-700 opacity-80" />} />
                                <MenuDropdownItem onClick={() => onDuplicate(item)} text={"Duplicate"} icon={<HiDuplicate className="w-5 h-5 mr-2 text-indigo-700 opacity-80" />} />
                                <MenuDropdownItem onClick={() => onDelete(item.id)} text={"Delete"} icon={<HiTrash className="w-5 h-5 mr-2 text-red-700 opacity-80" />} />
                            </div>
                        </MenuDropdown>
                    </div>
                    <div style={{float:"left", marginLeft:5}}>
                        <FaPlayCircle className={`w-5 h-5 scale-90 text-yellow-500 cursor-pointer transition duration-300 transform hover:scale-100`} title="Send test email" onClick={() => onSendMail(item, true)} /> 
                    </div>
                    <div style={{float:"left", marginLeft:5}}>
                        <FaPlayCircle className={`w-5 h-5 scale-90 text-green-500 cursor-pointer transition duration-300 transform hover:scale-100`}  title="Send email" onClick={() => onSendMail(item, false)} /> 
                    </div>
                    <div style={{float:"left", marginLeft:5}}>
                        {item.active ? <FaCheckCircle className={`w-5 h-5 scale-90 text-green-500 cursor-pointer transition duration-300 transform hover:scale-100`}  title="Active - click to disable" onClick={() => onToggleActive(item)} /> : 
                                       <FaTimesCircle className={`w-5 h-5 scale-90 text-red-500 cursor-pointer transition duration-300 transform hover:scale-100`} title="Disabled - click to activate" onClick={() => onToggleActive(item)} /> }
                    </div>
                    
                </TableBodyCell>
            </TableBodyRow>)}
        </TableBody>
    </Table>
}

export default ReportingTable