import { useEffect, useState } from "react"
import ServerAndAccountCard from "../components/Server/ServerAndAccountCard"
import { get } from "../helpers/Requests"
import { APAAccount, ServerData} from "../types/types"
import TabBar from "../components/TabBar"
import APASchedules from "../components/APA/APASchedules"
import QuarantinedAccounts from "./APAAccountsErrors"
import AccountErrors from "./APAAccountsErrors"
import { useDispatch, useStore } from "../contexts/StoreContext"

export type APAServerProps = {
    tab: number
}
const APAServers = (props:APAServerProps) => {
    const [APAservers, setAPAServers] = useState<ServerData[]>([])
    const [tab, setTab] = useState(props.tab)
    const {servers, accounts } = useStore()

    const dispatch = useDispatch()


    useEffect(() => {
        console.log(props.tab)
        setTab(props.tab)
        // getData()
    }, [])

    useEffect(()=> {
        if (servers) setAPAServers(servers.filter(s => s.type == "apa"))
    }, [servers])


    useEffect(() => {
        const getAccounts = async (serverId: number): Promise<APAAccount[]> => {
            try {
                const [result] = await Promise.all([get('apa/' + serverId + '/accounts')])
                return result;
            } catch (e) {
                console.log(e)
                return []
            }
        }

        const getAllAccounts = async (servers: ServerData[]): Promise<APAAccount[]> => {
            let result: APAAccount[] = []
            for (let j = 0; j < servers.length; j++) {

                const [accounts] = await Promise.all([getAccounts(servers[j].id)])
                for (let i = 0; i < accounts.length; i++) {
                    accounts[i].serverId = servers[j].id
                }
                result = result.concat(accounts)
            }
            return result;
        }

        async function fetch() {
            const accounts = await getAllAccounts(servers)
            dispatch({ type: "set_accounts", accounts: accounts })
        }
        fetch();
    }, [servers])

    return <div className="bg-neutral-100 min-h-full pb-8">
        <div className="flex justify-between items-end mb-4">
            <div className="px-4 py-2"><h1 className="text-gray-500 text-lg font-semibold">APA</h1></div>
        </div>
        <TabBar style={{marginBottom: 10, marginLeft: 15}} selected={tab} tabs={["Schedules", "Accounts", "Errors"]} setTab={setTab} />
        <div className="mx-4 flex flex-row gap-4">
            <div className="flex-1 bg-white rounded-md shadow-md">
                {tab === 0 && <APASchedules />}
                {tab === 1 && APAservers.map((s, id) => <ServerAndAccountCard key={id} server={s} /> ) }
                {tab === 2 && <AccountErrors /> }
            </div>
            <div className="flex-1"></div>
        </div>
    </div>
}

export default APAServers