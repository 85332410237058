import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Button from "../components/Button";
import AddEmail from "../components/Reporting/AddEmail";
import ReportingTable from "../components/Reporting/ReportingTable";
import { del, get, post, put } from "../helpers/Requests";
import { CloudReport, CloudReportObj, CloudUser } from "../types/cloud_types";
import { Report, Plan, TrendView } from "../types/reporting_types";
import { GoGraph } from "react-icons/go";
import { ReportingOrgItem } from "./ReportingOrganization";
import { useSideBarMenuContext } from "../components/Sidebar/SideBarContext";
import { ServerData} from "../types/types"

const ReportingTrendViews: React.FC<{
    server: ServerData | undefined,
    reporting_reports: TrendView[],
    plans: Plan[],
    users: CloudUser[],
    update(): any,
    sendmail(id: number, email: string, test: boolean): void,
}> = ({ server, reporting_reports, plans, users, update, sendmail }) => {
    const { orgId } = useParams()
    const { setUp } = useSideBarMenuContext()
    const serverId = server?.id.toString()

    const [add, setAdd] = useState(false);
    const [editReportEmail, setEditReportEmail] = useState<TrendView | null>(null);
    const [cloudReportsObj, setCloudReportsObj] = useState<CloudReportObj>()
    const [cloudReports, setCloudReports] = useState<CloudReport[]>([])

    useEffect(() => {
        setUp("/reporting")
        get(`cloud/${server?.cloud}/reports/${orgId}`) // cloud reports are retrieved in order to display report name in the table
            .then((res: CloudReport[]) => {
                // making an object of all reports with id as key for faster lookup when rendering in table
                const _tmp: CloudReportObj = res.reduce((acc: CloudReportObj, cur) => { acc[cur.report_id] = cur; return acc }, {})
                setCloudReportsObj(_tmp)
                setCloudReports(res)
            }).catch(err => console.log(err))
    }, [orgId, setUp, server])

    const onClose = () => {
        setEditReportEmail(null)
        setAdd(false);
    }

    const onEdit = (report: TrendView) => {
        setEditReportEmail(report);
        setAdd(true)
    }

    const onDelete = (id: number) => {
        del(`reporting/${serverId}/trendview/${id}`).then(res => {
            update()
        }).catch(err => console.log(err))
    }

    const onDuplicate = (d: TrendView) => {
        d.id = 0
        post(`reporting/${serverId}/trendview`, d).then(res => {
            update()
        }).catch(err => console.log(err))
    }

    const onToggleActive =(d: Report) => {
        d.active = !d.active
        put(`reporting/${serverId}/trendview/${d.id}`, d).then(res => {
            update();
        }).catch(err => console.log(err))
    }
    
    const onSendMail = (d: Report, test:boolean) => {
        sendmail(d.plan_id?? 0, d.email, test)
    }

    return <div className="">
        <div className="flex justify-between items-center mb-4">
            <div className="d">
                <ReportingOrgItem items={reporting_reports} plans={plans} bgColor="bg-red-100" textColor="text-red-500" icon={<GoGraph className={`w-5 h-5 text-red-500`} />} />

            </div>
            <div><Button text="Add" color="primary" onClick={_ => setAdd(true)} /></div>
        </div>
        <div></div>
        <ReportingTable items={reporting_reports} plans={plans} color="trendview" cloudObj={cloudReportsObj ?? {}} onEdit={onEdit} onDelete={onDelete} onDuplicate={onDuplicate} onToggleActive={onToggleActive} onSendMail={onSendMail} />
        <AddEmail orgId={parseInt(orgId ?? "-1")} server={server} users={users} cloudItems={cloudReports} open={add} route="trendview" onClose={onClose} getEmailItems={update} edittedEmailItem={editReportEmail} plans={plans} />
    </div>
}

export default ReportingTrendViews