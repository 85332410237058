import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"
import { get } from "../helpers/Requests";
import { CloudOrgWithReporting, Dashboard, DashboardTable, TrendView, Report, ReportingStats, Plan } from "../types/reporting_types";
import { GoDashboard, GoGraph } from "react-icons/go";
import { FaFileCsv } from "react-icons/fa";
import ReportingItem from "../components/Reporting/ReportingItem";
import OrganizationListItem from "../components/Reporting/OrganizationListItem";
import { getReportingStats } from "../helpers/reporting_helpers";
import { useSideBarMenuContext } from "../components/Sidebar/SideBarContext";
import { ServerData} from "../types/types"

const Reporting = () => {
    const { serverId } = useParams()

    const { setUp } = useSideBarMenuContext()
    const navigate = useNavigate();

    const [server, setServer] = useState<ServerData>()

    const [dashboards, setDashboards] = useState<Dashboard[]>([]);
    const [reports, setReports] = useState<Report[]>([]);
    const [dashboardtables, setDashboardTabless] = useState<DashboardTable[]>([]);
    const [trendviews, setTrendViews] = useState<TrendView[]>([]);

    const [plans, setPlans] = useState<Plan[]>([]);
    const [cloudOrganizations, setCloudOrganizations] = useState<CloudOrgWithReporting[]>([]);

    const allDashboardStats: ReportingStats = useMemo(() => {
        return getReportingStats(dashboards, plans)
    }, [dashboards, plans]);

    const allDashboardTableStats: ReportingStats = useMemo(() => {
        return getReportingStats(dashboardtables, plans)
    }, [dashboardtables, plans]);

    const allTrendViewStats: ReportingStats = useMemo(() => {
        return getReportingStats(trendviews, plans)
    }, [trendviews, plans]);

    const allReportStats: ReportingStats = useMemo(() => {
        return getReportingStats(reports, plans)
    }, [reports, plans]);

    useEffect(() => {
            get(`servers/${serverId}`)
            .then(setServer)
            .catch(err => {
                console.log(err)
            })
        }, [serverId])


    useEffect(() => {
        setUp(null)
    }, [setUp])

    useEffect(() => {
        if(serverId && server?.cloud){
            Promise.all([get("reporting/" + serverId + "/dashboard"), get("reporting/" + serverId + "/dashtable"), get("reporting/" + serverId + "/trendview"), get("reporting/" + serverId + "/report"), get("reporting/" + serverId + "/plan"), get("cloud/" + server?.cloud + "/organizations")])
                .then(([dashboards, dashboardtables, trendviews, reports, plans, cloudOrgs]) => {
                    const sorted: CloudOrgWithReporting[] = cloudOrgs.filter((x:CloudOrgWithReporting) => !x.deleted).sort((a: CloudOrgWithReporting, b: CloudOrgWithReporting) => a.name.toLocaleLowerCase().localeCompare(b.name))
                    sorted.forEach(cd => {
                        cd.dashboards = dashboards.filter((d: Dashboard) => cd.id === d.org_id)
                        cd.reports = reports.filter((r: Report) => cd.id === r.org_id)
                        cd.dashboardtables = dashboardtables.filter((d: DashboardTable) => cd.id === d.org_id)
                        cd.trendviews = trendviews.filter((d: DashboardTable) => cd.id === d.org_id)
                    })

                    setCloudOrganizations(sorted);
                    setDashboards(dashboards);
                    setDashboardTabless(dashboardtables);
                    setTrendViews(trendviews);
                    setReports(reports);
                    setPlans(plans)
                }).catch(err => console.log(err))
        }
    }, [server, serverId]);

    return <div className="bg-neutral-100 min-h-full pb-8">
        <div className="px-4 py-2"><h1 className="text-gray-500 text-lg font-semibold">Overview</h1></div>
        <div className="px-4 py-2 flex flex-row gap-4">
            <ReportingItem stats={allDashboardStats} bgColor="bg-blue-100" color="text-blue-500" name="dashboard" count={dashboards.length} icon={<GoDashboard className="h-6 w-6 text-blue-500" />} />
            <ReportingItem stats={allDashboardTableStats} bgColor="bg-yellow-100" color="text-yellow-500" name="dashboard table" count={dashboardtables.length} icon={<GoDashboard className="h-6 w-6 text-yellow-500" />} />
        </div>
        <div className="px-4 py-2 flex flex-row gap-4">
            <ReportingItem stats={allTrendViewStats} bgColor="bg-red-100" color="text-red-500" name="trend view" count={trendviews.length} icon={<GoGraph className="h-6 w-6 text-red-500" />} />
            <ReportingItem stats={allReportStats} bgColor="bg-green-200" color="text-green-500" name="report" count={reports.length} icon={<FaFileCsv className="h-6 w-6 text-green-500" />} />
        </div>

        <div className="px-4 py-2 mt-2"><h1 className="text-gray-500 text-lg font-semibold">Organizations</h1></div>
        <div className="mx-4 flex flex-row gap-4">
            <div className="flex-1 bg-white rounded-md shadow-md">
                {cloudOrganizations.map((org, id) => <OrganizationListItem key={id} org={org} onClick={() => navigate(`/reporting/${serverId}/${org.id}`)} />)}
            </div>
            <div className="flex-1"></div>
        </div>
    </div>;
}

export default Reporting;