import { ReportingStats } from "../../types/reporting_types";

interface ReportingItemProps extends React.ComponentPropsWithoutRef<"div"> {
    name: string,
    count: number,
    icon: React.ReactNode,
    color: string,
    stats: ReportingStats,
    clickable?: boolean,
    bgColor: string
}

const ReportingItem: React.FC<ReportingItemProps> = ({ name, count = 0, icon, color, stats, clickable, bgColor, ...props }) => {
    return <div {...props} className={`flex-1 group p-4 bg-white border-gray-500 shadow-md rounded-md ${clickable ? 'transition duration-200 transform hover:scale-[1.02] hover:shadow-lg cursor-pointer' : ''}`}>
        <div className="flex flex-row items-center justify-between gap-4">
            <div className="flex flex-row items-center gap-6 px-2">
                <div className={`p-4 rounded-lg ${bgColor} bg-opacity-50`}>{icon}</div>
                <div className="flex flex-col">
                    <div className="text-gray-500 text-sm">All {name} emails</div>
                    <ReportingItemStats color={color} count={count} stats={stats} />
                </div>
            </div>
            <div>
                {clickable && <div className="px-2 text-gray-500 text-sm opacity-0 group-hover:opacity-100">Configure</div>}
            </div>
        </div>
    </div>
}

export const ReportingItemStats: React.FC<{ color: string, count: number, stats: ReportingStats }> = ({ color, count, stats }) => {
    return <>
        <div className="flex flex-row gap-4 items-center">
            <div className={`text-3xl font-semibold ${color}`}>{count}</div>
            <div className="flex flex-row gap-4 border-l border-gray-300 pl-4">
                <div className="text-xs font-semibold border border-blue-200  text-blue-600 rounded-full py-1 px-2">Daily: <span className="text-blue-900">{stats.daily}</span></div>
                <div className="text-xs font-semibold border border-green-300 text-green-600 rounded-full py-1 px-2">Weekly: <span className="text-green-900">{stats.weekly}</span></div>
                <div className="text-xs font-semibold border border-red-200 text-red-600 rounded-full py-1 px-2">Monthly: <span className="text-red-900">{stats.monthly}</span></div>
            </div>
        </div>
    </>
}

export default ReportingItem;
