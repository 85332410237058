import { APAAccount } from "../types/types";
import { Store } from "../contexts/StoreContext";

export function accountReducer(state: Store, action: any) {
    switch (action.type) {
        case 'change_account': {
            const o: APAAccount[] = [...state.accounts]
            const index = o.findIndex(o => action.id === o.id)
            o.splice(index, 1, action.account)
            return o;
        }
        case 'set_accounts': {
            return action.accounts
        }
        default: {
            return state.accounts;
        }
    }
}
