import { Store } from "../contexts/StoreContext";

export function latestRunReducer(state: Store, action: any) {
    switch (action.type) {
        case 'change_latest_runs': {
            const r = [...state.latest_runs]
            const index = r.findIndex(run => action.id === run.id)
            r.splice(index, 1, action.org)
            return r
        }
        case 'set_latest_runs': {
            return action.runs
        }

        default: {
            return state.latest_runs
        }
    }
}
