import { useState } from "react"
import EditPermissions from "./EditPermissions"
import { CloudUser } from "../../types/cloud_types"
import { Modal } from "../Modals"

export type EditMultipleProps = {
    selected: CloudUser[],
    closeModalHandler: Function,
    shown: boolean,
    type: "user" | "permissions",
    title: string,
    savePermissions: Function,
}

export default function EditMultiple(props: EditMultipleProps) {
    const [permissions, setPermissions] = useState<Permissions[]>([])

    function permissionsChangedHandler(perms: Permissions[]) {
        setPermissions(perms)
    }

    function getPermissions() {
        let allPerms = props.selected.map((s: CloudUser) => s.permissions)
        let allEqual = true
        if (allPerms.length > 1) {
            for (let i = 0; i < allPerms.length - 1 && allEqual; i++) {
                let first = allPerms[i] ? allPerms[i].sort() : []
                let second = allPerms[i + 1] ? allPerms[i + 1].sort() : []

                if (first.length !== second.length) {
                    allEqual = false
                }
                else {
                    for (let j = 0; j < first.length && allEqual; j++) {
                        if (first[j] !== second[j]) {
                            allEqual = false
                        }
                    }
                }
            }
        }

        if (allPerms.length > 0 && allEqual) {
            return allPerms[0]
        }
        else {
            return []
        }
    }

    function saveChangesHandler() {
        const userIds = props.selected.map(s => s.id)
        props.savePermissions(permissions, userIds)
    }

    return (
        <Modal isOpen={props.shown} height='h-[85vh]' actionText="Save" closeText="Cancel" size="x-large" onAction={() => saveChangesHandler()} onClose={() => null} title={props.title}  >
            <div id="edit-user-modal" role="dialog">
                <div className="" role="document">
                    <div className="">
                        <div className="mx-auto py-4 px-1 ">
                            <EditPermissions multiEdit={true} getPermissionsHandler={getPermissions} permissionsChangedHandler={permissionsChangedHandler} />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}