import Cookies from 'universal-cookie'

const cookies = new Cookies()
const apiRoot = '/api/'
const tokenKey = 'token'

export const saveToken = (token: string) => {
    cookies.set(tokenKey, token)
}

export const getToken = () => {
    return cookies.get(tokenKey)
}

export const clearToken = () => {
    cookies.remove(tokenKey)
}

export const get = (url: string) => {
    return fetch(apiRoot + url,
        {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + getToken(),
            },
        }).then(res => res.json())
}

export const getRaw = (url: string) => {
    return fetch(apiRoot + url,
        {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + getToken(),
            },
        })
}

export const post = (url: string, body: any) => {
    return fetch(apiRoot + url, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + getToken(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    }).then(res => res.json())
}

export const postRaw = (url: string, body: any) => {
    return fetch(apiRoot + url, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + getToken(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    })
}

export const postForm = (url: string, body: FormData) => {
    return fetch(apiRoot + url, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + getToken(),
        },
        body: body
    })
}

export const putForm = (url: string, body: FormData) => {
    return fetch(apiRoot + url, {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + getToken(),
        },
        body: body
    })
}

export const put = (url: string, body: any) => {
    return fetch(apiRoot + url, {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + getToken(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    }).then(res => res.json())
}

export const del = (url: string) => {
    return fetch(apiRoot + url, {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + getToken(),
        },
    }).then(res => res.json())
}
