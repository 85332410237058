import { Dispatch, SetStateAction, useEffect, useState } from "react";
import type { CloudOrgInfo } from "../../types/cloud_types";
import { Modal } from "../Modals";
import { put } from "../../helpers/Requests";
import { SimpleToastProps } from "../Toasts/SimpleToast";

interface AdminPanelSettingsModalProps {
    isOpen: boolean
    onClose: any
    dispatch: any
    org: CloudOrgInfo
    toastConfig: SimpleToastProps
    setToastConfig: Dispatch<SetStateAction<SimpleToastProps>>
}

function AdminPanelSettingsModal({isOpen, org, onClose, dispatch, toastConfig, setToastConfig}:AdminPanelSettingsModalProps){
    const [addLocationField, setAddLocationField] = useState<boolean>(org.location_field_enabled ?? false);
    const [locationFieldMandatory, setLocationFieldMandatory] = useState<boolean>(org.location_field_mandatory ?? false)
    
    useEffect(() => {
        if(!addLocationField){
            setLocationFieldMandatory(false);
        }
    }, [addLocationField])

    console.log(org);
    

    const saveLocationFieldSettings = async () => {
        const locationFieldData = {
            location_field_enabled: addLocationField,
            location_field_mandatory: locationFieldMandatory
        }

        const url = `cloud/${org.server?.id}/organizations/${org.id}/location_field`
        await put(url, locationFieldData).then(res => {
            if (!!res) {
                console.log(org);
                console.log(res);
                org.location_field_enabled = res.location_field_enabled
                org.location_field_mandatory = res.location_field_mandatory
                const updatedOrg = {...org}
                dispatch({ type: "change_org", org: updatedOrg })
                const config = { ...toastConfig }
                config.text = "Location field settings updated successfully"
                config.show = true;
                config.type = "success"

                setToastConfig(config)
                setTimeout(() => {
                    const c = { ...config }
                    c.show = false;
                    setToastConfig(c)
                }, 2000)
            } else {
                const tConfig = { ...toastConfig }
                tConfig.text = `Something went wrong updating location field settings`
                tConfig.show = true;
                tConfig.type = "danger"

                setToastConfig(tConfig)

                setTimeout(() => {
                    const c = { ...tConfig }
                    c.show = false;
                    setToastConfig(c)
                }, 2000)
            }
        })
        onClose();
    }
    
    return( 
        <Modal 
            isOpen={isOpen} 
            title={org.name + "- Admin Panel Settings"} 
            actionText="Save" 
            onAction={saveLocationFieldSettings}
            closeText="Close"
            onClose={onClose} 
        >
            <div className="p-8 flex flex-col gap-4">
                <div className="flex gap-2 items-center">
                    <label className="flex gap-2 items-center">
                        <input 
                            type="checkbox" 
                            className="rounded focus:ring-transparent" 
                            checked={addLocationField}
                            onChange={() => setAddLocationField((current) => !current)}
                            />
                        Add Location field
                    </label>
                    {addLocationField &&
                        <label className="flex gap-2 items-center">
                        <input 
                            type="checkbox" 
                            className="rounded focus:ring-transparent" 
                            checked={locationFieldMandatory}
                            onChange={() => setLocationFieldMandatory((current) => !current)}
                            />
                        Mandatory
                    </label>
                    }
                </div>
                
            </div>
        </Modal>
    );
}

export default AdminPanelSettingsModal;