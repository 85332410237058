import { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import Button from "../components/Button";
import AddEmail from "../components/Reporting/AddEmail";
import ReportingTable from "../components/Reporting/ReportingTable";
import { del, get, post, put } from "../helpers/Requests";
import { CloudDashboard, CloudDashboardObj, CloudUser } from "../types/cloud_types";
import { DashboardTable, Plan} from "../types/reporting_types";
import { ReportingOrgItem } from "./ReportingOrganization";
import { GoDashboard } from "react-icons/go";
import { useSideBarMenuContext } from "../components/Sidebar/SideBarContext";
import { ServerData} from "../types/types"

const ReportingDashboardTables: React.FC<{
    server: ServerData | undefined,
    reporting_dashboards: DashboardTable[],
    plans: Plan[],
    users: CloudUser[],
    update(): any,
    sendmail(id: number, email: string, test: boolean): void,
}> = ({ server, reporting_dashboards, plans, users, update, sendmail }) => {
    const { orgId } = useParams()
    const { setUp } = useSideBarMenuContext()
    const serverId = server?.id.toString()

    const [add, setAdd] = useState(false);
    const [editDashboardEmail, setEditDashboardEmail] = useState<DashboardTable | null>(null);
    const [cloudDashboardsObj, setCloudDashboardsObj] = useState<CloudDashboardObj>()
    const [cloudDashboards, setCloudDashboards] = useState<CloudDashboard[]>([])

    useEffect(() => {
        setUp(`/reporting/${orgId}`)
        get(`cloud/${server?.cloud}/dashboards/${orgId}`) // cloud dashboards are retrieved in order to display dashboard name in the table
            .then((res: CloudDashboard[]) => {
                // making an object of all dashboards with id as key for faster lookup when rendering in table
                const _tmp: CloudDashboardObj = res.reduce((acc: CloudDashboardObj, cur) => { acc[cur.id] = cur; return acc }, {})
                setCloudDashboardsObj(_tmp)
                setCloudDashboards(res)
            }).catch(err => console.log(err))
    }, [orgId, setUp, server])

    const onClose = () => {
        setEditDashboardEmail(null)
        setAdd(false);
    }

    const onEdit = (dashboard: DashboardTable) => {
        setEditDashboardEmail(dashboard);
        setAdd(true)
    }

    const onDelete = (id: number) => {
        del(`reporting/${serverId}/dashtable/${id}`).then(res => {
            update()
        }).catch(err => console.log(err))
    }
 
    const onDuplicate = (d: DashboardTable) => {
        d.id = 0
        post(`reporting/${serverId}/dashtable`, d).then(res => {
            update()
        }).catch(err => console.log(err))
    }
   
    const onToggleActive =(d: DashboardTable) => {
        d.active = !d.active
        put(`reporting/${serverId}/dashtable/${d.id}`, d).then(res => {
            update();
        }).catch(err => console.log(err))
    }

    const onSendMail = (d: DashboardTable, test:boolean) => {
       sendmail(d.plan_id?? 0 , d.email, test )
    }


    return <div className="">
        <div className="flex justify-between items-end mb-4">
            <div className="">
                <ReportingOrgItem items={reporting_dashboards} plans={plans} bgColor="bg-yellow-100" textColor="text-yellow-500" icon={<GoDashboard className={`w-5 h-5 text-yellow-500`} />} />
            </div>
            <div><Button text="Add" color="primary" onClick={_ => setAdd(true)} /></div>
        </div>
        <div></div>
        <ReportingTable items={reporting_dashboards} plans={plans} cloudObj={cloudDashboardsObj ?? {}} color="dashboardtable" onEdit={onEdit} onDelete={onDelete} onDuplicate={onDuplicate} onToggleActive={onToggleActive} onSendMail={onSendMail} />
        <AddEmail server={server} orgId={parseInt(orgId ?? "-1")} users={users} cloudItems={cloudDashboards} open={add} route="dashtable" onClose={onClose} getEmailItems={update} edittedEmailItem={editDashboardEmail} plans={plans} />
    </div>
}

export default ReportingDashboardTables