import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import ServerCard from "./ServerCard"
import APAAccountCard from "../APA/APAAccountCard"
import { APAAccount, ServerData } from "../../types/types"
import { get } from "../../helpers/Requests"
import { useStore } from "../../contexts/StoreContext"

const ServerAndAccountCard: React.FC<{ server: ServerData}> = ({ server }) => {
    const [accs, setAccs] = useState<APAAccount[]>([])
    const { servers, accounts } = useStore()

    const navigate = useNavigate()

    // const getData = useCallback(() => {
    //     get('apa/' + server.id +'/accounts')
    //     .then(setAccounts)
    //     .catch(err => console.log(err))
    // }, [server])

    useEffect(() => {
        if(accounts) {
            const accs = accounts.filter(a => a.serverId === server.id)
        }
    }, [accounts])
    
    return <div><ServerCard key={server.id} server={server} onClick={() => navigate(`/apa/${server.id}`)} />
    {
        accs.map((acc, accId) => 
            <><APAAccountCard key={accId} serverId={server.id.toString()} account={acc} onClick={() => navigate(`/apa/${server.id}/${acc.id}`)} /></>
        )
    }
    </div>

}

export default ServerAndAccountCard