import { APAServerAccounts, ServerData } from '../types/types';
import { CloudServerOrganization } from './../types/cloud_types';
export interface BreadcrumbProps {
    name: string;
    path: string;
}

export const getBreadcrumbs = (path: string[], orgs: CloudServerOrganization[], serverAccounts: APAServerAccounts[], servers: ServerData[]): BreadcrumbProps[] => {
    const breadcrumbs: BreadcrumbProps[] = [];
    let url = ""
    path.forEach((p, i) => {
        url += "/" + p
        switch (p) {
            case '': breadcrumbs.push({ name: 'Home', path: url }); break;
            case 'user': breadcrumbs.push({ name: 'Settings', path: url }); break;
            case 'reporting': breadcrumbs.push({ name: "Reporting", path: url }); break;
            case 'cloud': breadcrumbs.push({ name: "Cloud", path: url }); break;
            case 'apa': breadcrumbs.push({ name: "APA", path: url }); break;
            case 'servers': breadcrumbs.push({ name: "Servers", path: url }); break;
            case 'organizations': breadcrumbs.push({name: "Organizations", path:url}); break;
            default: {
                let name = p
                if (i > 0 && path[i - 1] === "cloud" && servers) {
                    const serverId = parseInt(p, 10)
                    const server = servers.find(o => o.id === serverId);
                    if (server) name = server.short_name;
                }
                
                if (i > 0 && path[i - 1] === "reporting" && servers) {
                    const serverId = parseInt(p, 10)
                    const server = servers.find(o => o.id === serverId);
                    if (server) name = server.short_name;
                }
                if (i > 0 && path[i - 2] === "reporting" && servers && orgs) {
                    const orgId = parseInt(p, 10)
                    const serverId = parseInt(path[i - 1], 10)
                    const server =  servers.find(o => o.id === serverId);
                    const organizations = orgs.find(o => o.server.id === server?.cloud)?.organizations
                    if (organizations) {
                        const organization = organizations.find(a => a.id === orgId);
                        if (organization) {
                            name =organization?.name
                        }
                    }
                }

                if (i > 0 && path[i - 1] === "apa" && servers) {
                    const serverId = parseInt(p, 10)
                    const server = servers.find(o => o.id === serverId);
                    if (server) name = server.short_name;
                }
                if (i > 1 && path[i - 2] === "apa" && servers && serverAccounts) {
                    const accId = parseInt(p, 10)
                    const serverId = parseInt(path[i - 1], 10)
                    const accounts = serverAccounts.find(o => o.server.id === serverId)?.accounts
                    if (accounts) {
                        const account = accounts.find(a => a.id === accId);
                        if (account) {
                            name =account?.name
                        }
                    }
                }

                if ( i === 2 && path[0] === "organizations" && path[2] === "setups") {
                    url = `/${path[0]}/${path[1]}/${path[2]}/${path[3]}/${path[4]}`
                }
                if (i > 2 && path[0] === "organizations" && path[2] === "setups") {
                    const index = breadcrumbs.findIndex((b) => b.name === "setups")
                    const newBreadcrumb = {
                        name: "Setup",
                        path: `/${path[0]}/${path[1]}/${path[2]}/${path[3]}/${path[4]}`
                    }

                    breadcrumbs.splice(index, 1, newBreadcrumb)
                    return
                }

                if(i === 2 && path[2] === "track_status") {
                    name = "Track status"
                }

                breadcrumbs.push({ name: name, path: url });
            }
        }
    })

    return breadcrumbs
}

export function getDateString(unixTime: number): string {
    if (unixTime === 0) return "-"

    let a = new Date(unixTime * 1000);
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let year = a.getFullYear();
    let month = months[a.getMonth()];
    let date = a.getDate();
    let hour = "0" + a.getHours();
    let min = "0" + a.getMinutes();
    let sec = "0" + a.getSeconds();
    let time = date + ' ' + month + ' ' + year + ' ' + hour.slice(-2) + ':' + min.slice(-2) + ':' + sec.slice(-2);
    return time;
}