import React, { useCallback, useContext, useEffect, useReducer, useState } from "react"
import { get, post } from "../helpers/Requests"
import { Outlet, useNavigate, useOutletContext } from "react-router-dom"
import { APAAccount, APASetup, CompanyData, NewCompanyProps, ServerData } from "../types/types"
import { CloudOrgInfo, CloudOrganization, CloudServerOrganization, CloudUser } from "../types/cloud_types"
import { AppDispatchContext, StoreContext, useDispatch, useStore } from "../contexts/StoreContext"
import { getOrganizations } from "../api/organizations.api"


type ContextType =
    {
        orgClickedHandler: Function,
        selectedOrg?: CloudOrgInfo,
        updateOrg: Function,
    };

const Organizations = () => {
    const navigate = useNavigate()
    const [selectedOrg, setSelectedOrg] = useState<CloudOrgInfo | null>(null)
    const { orgs, servers, } = useStore()
    const dispatch = useDispatch()

    useEffect(() => { // Runs once
        if (orgs.length === 0 && !!servers) {
            const typedServers = servers as ServerData[]
            if (typedServers) {
                getOrganizations(typedServers).then(result => {
                    let filtered = result.filter((o: CloudOrgInfo) => !o.deleted)
                    dispatch({ type: "set_orgs", orgs: filtered })
                    return filtered
                }).then(orgs => {
                    getApaAccounts(typedServers, orgs)
                })
                    .catch(err => {
                        console.log(err)
                    });
            }
        }

    }, [servers])

    function organizationClickedHandler(id: string, serverID: string = "") {
        const orgIndex = orgs.findIndex((o: any) => o.id + "" === id);
        const org = orgs[orgIndex]

        if (!!org?.users && org.users.length > 0) {
            setSelectedOrg(org)
            navigate(`/organizations/${org.id} `)
        } else {
            get(`cloud/${serverID}/organizations/${id}/users`)
                .then((users) => {
                    let newOrg = { ...org };
                    newOrg.users = users;
                    dispatch(({
                        type: 'change_org',
                        id: newOrg.id,
                        org: newOrg
                    }))

                    setSelectedOrg(newOrg)
                    navigate("/organizations/" + org.id)
                })
        }
    }

    async function getApaAccounts(s: ServerData[], orgs: CloudOrgInfo[]) {
        s.forEach(server => {
            if (server.type === "apa") {
                get('apa/' + server.id + '/accounts')
                    .then((data: APAAccount[]) => {

                        const o = [...orgs];
                        o.forEach((org, i) => {
                            const index = data.findIndex((d) => d.organization_id === org.id)
                            if (index > -1) {
                                org.account = data[index]
                                org.account.serverId = server.id;
                                o.splice(i, 1, org)
                            }
                        })
                        getQuarantinedSetups(servers, data, o)
                    })
                    .catch(e => console.log(e))
            }
        })

    }

    async function getQuarantinedSetups(servers: ServerData[], accounts: APAAccount[], orgs: CloudOrgInfo[]) {
        servers.forEach(server => {

            if (server.type === "apa") {
                get('apa/' + server.id + '/setups/quarantined')
                    .then((data) => {
                        let os: CloudOrgInfo[] = [...orgs];
                        data.forEach((d: APASetup) => {
                            if (d.quarantined) {
                                const oIndex = os.findIndex(o => o.account?.id === d.account_id)
                                const org: CloudOrgInfo = os[oIndex]
                                org.quarantined = d;
                                os.splice(oIndex, 1, org)

                            }
                        })

                        dispatch({ type: "set_orgs", orgs: os })
                    })
                    .catch(e => console.log(e))
            }
        })
    }

    return (

        <div className="mx-auto my-0">
            <Outlet context={{ orgClickedHandler: organizationClickedHandler, selectedOrg: selectedOrg }} />
        </div>
    )
}

export default Organizations

export function useOrgs() {
    return useOutletContext<ContextType>()
}
