import { useState } from 'react'
import { Combobox, ComboboxOptionsProps } from '@headlessui/react'
import { BiCheck, BiChevronDown, BiChevronUp } from 'react-icons/bi'
import { types } from '@babel/core'

type option = {
    id: number | string,
    value: string | number,
    label: string,
    disabled?: boolean,

}

type IProps = {
    options: option[],
    selectedOption?: option,
    setSelectedOption: React.Dispatch<any>,
    label?: string,
    disabled?: boolean,
    showValue?: boolean,
    placeholder?: string

}

function CustomCombobox(props:IProps) {
    const [query, setQuery] = useState('')

    const filteredOptions =
        query === ''
            ? props.options
            : props.options.filter((option) => {
                return option.label.toLowerCase().includes(query.toLowerCase())
            })
    return (

        <div className="relative w-full">
        
            
            <Combobox disabled={props.disabled} value={!!props.showValue ? props.selectedOption : null} onChange={(option) => { if (!!option) props.setSelectedOption(option) }} >
        {!!props.label && <Combobox.Label className="text-xs text-gray-600">{props.label}</Combobox.Label> }
                <div className="relative mt-1 overflow-auto ">
                    <div className={`relative w-full cursor-default border border-gray-300  rounded-md bg-white text-left focus:outline-none sm:text-sm focus-within:border-tangerine-900 focus-within:border-2 ${props.disabled ? "opacity-20" : ""}`}>
                
            <Combobox.Input
                onChange={(event) => setQuery(event.target.value)}
                displayValue={(option:option) => option?.label}
                            className={`w-full border-none py-1 pl-3 my-[2px] pr-10 text-sm leading-5 text-gray-900 focus:ring-0 ${props.disabled ? "bg-gray-300 my-0 py-2" : ""}`}
            />
            </div>
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                    <BiChevronDown
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                    />
                </Combobox.Button>
            </div>
                <div className="absolute mt-[2px] w-full z-10  rounded-md  bg-white  p-1 text-base  focus:outline-none sm:text-sm">

                    <Combobox.Options className="overflow-auto scroll-mr-1 max-h-60 " >
                        {!filteredOptions || filteredOptions.length === 0 ? (
                    <div className="relative cursor-default select-none py-2 px-2 text-gray-700">
                        Nothing found.
                    </div>
                ) :
                filteredOptions.map((option) => (
                    <Combobox.Option key={option.id} value={option} 
                        className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-3 pr-1 ${active ? 'bg-dark-forest-700 text-white' : 'text-gray-900'
                            }`
                        }
                        
                        >
                        {({ selected, active }) => (
                            <>
                                <span
                                    className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                        }`}
                                >
                                    {option.label}
                                </span>
                                {selected ? (
                                    <span
                                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-sky-600'
                                            }`}
                                    >
                                        <BiCheck className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                ) : null}
                            </>
                        )}
                    </Combobox.Option>
                ))}
            </Combobox.Options>
            </div>
        </Combobox>

        </div>
   
    )
}

export default CustomCombobox