import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import UAParser from 'ua-parser-js'
import { get, post } from "../helpers/Requests"
import { CloudError, CloudOrganization, CloudUser } from "../types/cloud_types"
import { Table, TableHeadRow, TableBodyRow, TableHeadCell, TableBodyCell, TableHead, TableBody, } from '../components/Table'
import moment from "moment"
import { Dialog } from "@headlessui/react"
import { BsArrowsFullscreen} from 'react-icons/bs'
import { BiArchiveIn} from 'react-icons/bi'

const CloudErrors = () => {
    const { serverId } = useParams()
    const [errors, setErrors] = useState<CloudError[]>([])
    const [users, setUsers] = useState<CloudUser[]>([])
    const [organizations, setOrganizations] = useState<CloudOrganization[]>([])
    const [openedError, setOpenedError] = useState<number>(-1)
    const [openedArchiveError, setOpenedArchiveError] = useState<number>(-1)
    const [openedArchiveMultiple, setOpenedArchiveMultiple] = useState<boolean>(false)
    const [selectedErrors, setSelectedErrors] = useState<number[]>([])
    const [selectAll, setSelectAll] = useState(false)

    const getErrors = useCallback(() => {
        if (serverId) {
            Promise.all([
                get("cloud/" + serverId + "/errors"),
                get("cloud/" + serverId + "/users"),
                get("cloud/" + serverId + "/organizations")
            ]).then(([errors, users, organizations]) => {
                setErrors(errors);
                setUsers(users);
                setOrganizations(organizations);
            }).catch(err => console.log(err))
        }
    }, [serverId])
   
    useEffect(() => {
        getErrors()
    }, [getErrors])

    

    const getErrorTypeString = (errorType: number) => {
        switch (errorType) {
            case 1:
                return "API"
            case 2:
                return "Exception"
            default:
                return "Other/Unknown"
        }
    }

    const selectError= (checked:boolean, id:number) => {
        let localselectedErrors = selectedErrors.slice()
        if(checked) localselectedErrors.push(id)
        else localselectedErrors = localselectedErrors.filter(e => e !== id)
        
        setSelectedErrors(localselectedErrors)
        setOpenedArchiveError(-1);
    }

    const archiveError = (errorId:number) => {
        
        post("cloud/" + serverId + `/error/archive/${errorId}`, "").then(() => {
            selectError(false, errorId);
            getErrors();
        }).catch(err => console.log(err))

    }

    const archiveMultipleErrors = () => {

        let args = {ids: selectedErrors.slice() };
        post("cloud/"+ serverId + "/error/archive_multiple", args).then(() => {
            setSelectedErrors([])
            getErrors();
            setOpenedArchiveMultiple(false)
        }).catch(err => console.log(err))

    }

    useEffect(()=> {
        if(selectAll) {
            const err = errors.map(e => e.id) 
            setSelectedErrors(err)
        } else {
            setSelectedErrors([])
        }
    }, [selectAll])

    let popupError = errors[openedError]
    let popupErrorUser: CloudUser|undefined  = popupError ? users.find(u => u.id === popupError.user_id) : undefined
    let popupErrorOrg: CloudOrganization|undefined = popupError ?organizations.find(o => o.id === popupError.organization_id) : undefined
    let popupErrorUserAgent: UAParser.IResult|undefined = undefined
    if(popupError && popupError.user_agent) {
        var parser = new UAParser()
        parser.setUA(popupError.user_agent)
        popupErrorUserAgent = parser.getResult()
    }

    return <div className="bg-neutral-100 h-full">
        <div className="p-8 bg-white border-b border-gray-200">
            <h1 className="font-bold text-3xl mb-10">Error Reports</h1>
            <div>
               <Table>
                        <TableHead>
                            <TableHeadRow>
                                <TableHeadCell>User</TableHeadCell>
                                <TableHeadCell>Organization</TableHeadCell>
                                <TableHeadCell>Time</TableHeadCell>
                                <TableHeadCell>Occurrences</TableHeadCell>
                                <TableHeadCell>Error type</TableHeadCell>
                                <TableHeadCell>Info</TableHeadCell>
                                <TableHeadCell>Comment</TableHeadCell>
                                <TableHeadCell></TableHeadCell>
                                <TableHeadCell>
                                <span title="Select error" className="cursor-pointer">
                                    <input
                                        type="checkbox"
                                        checked={selectAll}
                                        onChange={() => setSelectAll(!selectAll)}
                                    />
                                </span>
                                </TableHeadCell>
                            <TableHeadCell className="w-[130px]">
                                     <button className="bg-gray-700 hover:bg-gray-900 w-[100px] text-white font-bold py-2 px-2 rounded disabled:bg-gray-300 disabled:hover:bg-gray-300"
                                        disabled={selectedErrors.length === 0} onClick={() => setOpenedArchiveMultiple(true)}>
                                        Archive {selectedErrors.length > 0 ? `(${selectedErrors.length})` : "(0)"}
                                    </button>
                                </TableHeadCell>
                            </TableHeadRow>
                        </TableHead>
                        <TableBody>
                            {
                                errors.map((e,i) => {
                                    let user = users.find(u => u.id === e.user_id)
                                    let org = organizations.find(o => o.id === e.organization_id)
                                    let errorInfo = e.error_type === 1 ? "HTTP "+e.http_code : e.error_name
                                    
                                    let checked = selectedErrors.includes(e.id)
                                    return (
                                        <TableBodyRow key={i}>
                                            <TableBodyCell>{user !== undefined ? user.firstname + " " + user.lastname : e.user_id}</TableBodyCell>
                                            <TableBodyCell>{org  !== undefined ? org.name : e.organization_id}</TableBodyCell>
                                            <TableBodyCell>{moment.unix(e.time).fromNow()}</TableBodyCell>
                                            <TableBodyCell>{e.count}</TableBodyCell>
                                            <TableBodyCell>{getErrorTypeString(e.error_type)}</TableBodyCell>
                                            <TableBodyCell>{errorInfo}</TableBodyCell>
                                            <TableBodyCell>
                                                <span title={e.comment} >
                                                    {e.comment}
                                               </span>
                                            </TableBodyCell>
                                            <TableBodyCell>
                                            <span title="Error details" onClick={() => setOpenedError(i)} className="cursor-pointer">
                                                <BsArrowsFullscreen />
                                                </span>
                                            </TableBodyCell>
                                            <TableBodyCell>
                                                <span title="Select error" className="cursor-pointer align-center">
                                                <input
                                                    type="checkbox"
                                                    checked={checked}
                                                    onChange={event => selectError(event.target.checked, e.id)}
                                                    />
                                                </span>
                                            </TableBodyCell>
                                            <TableBodyCell className="w-[130px] text-center inline-flex justify-center">
                                            <span title="Archive error" onClick={() => setOpenedArchiveError(e.id)} className="cursor-pointer text-center max-w-fit block">
                                                   <BiArchiveIn />
                                                </span>

                                            </TableBodyCell>
                                        </TableBodyRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
            </div>

        </div>

        <Dialog
                    open={openedError > -1}
                    onClose={() => setOpenedError(-1)}
				
                    className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen">
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

                <div className="relative bg-white rounded mx-auto p-5 drop-shadow-lg">
					<Dialog.Title className="text-2xl">
                        Error details 
                    </Dialog.Title>
                    <br />
						{
                            popupError ? 
                                <div id="errorPopup">
                                    <p><b>User:</b> {popupErrorUser ? popupErrorUser.firstname + " " + popupErrorUser.lastname : popupError.user_id}</p>
                                    <p><b>Organization:</b> {popupErrorOrg ? popupErrorOrg.name : popupError.organization_id}</p>
                                    <p><b>Comment:</b> {popupError.comment ? popupError.comment : <i>[None]</i>}</p>
                                    <p><b>Occurances:</b> {popupError.count}</p>
                                    <p><b>First occurrence in session:</b> {moment.unix(popupError.time).format("DD-MM-YYYY HH:mm")}</p>
                                    <p><b>Last occurrence in session:</b> {moment.unix(popupError.update_time).format("DD-MM-YYYY HH:mm")}</p>
                                    <p><b>URL:</b> {popupError.url}</p>
                                    <p><b>Version:</b> {popupError.software_version}</p>
                                    <p><b>Browser:</b> { popupErrorUserAgent ? 
                                        <>{popupErrorUserAgent.browser.name} {popupErrorUserAgent.browser.version ? "v" : ""}{popupErrorUserAgent.browser.version}</>
                                        :"unknown"
                                    }</p>
                                    {
                                    popupError.error_type === 1 ? 
                                        <div>
                                            <b>Method:</b> {popupError.http_method}<br/><br/>
                                            <b>Status code:</b> {popupError.http_code ? popupError.http_code : <i> [None]</i>}<br/><br/>
                                            <b>Endpoint:</b> {popupError.endpoint}<br/><br/>
                                            <b>Log message:</b> {popupError.message}<br/><br/>
                                            <b>Request data:</b> 
                                            {
                                                !popupError.data ? <><i> [None]</i><br/></> : 
                                                <details>
                                                    <summary>Click to expand</summary>
                                                    <pre>{popupError.data}</pre>
                                                </details>
                                            }
                                            <br/>
                                        </div>
                                    :
                                        <div>
                                            <b>Exception:</b> {popupError.error_name}<br/><br/>
                                            <b>Message:</b> {popupError.message}<br/><br/>
                                            <b>Stack trace:</b> 
                                            <details>
                                                <summary>Click to expand</summary>
                                                <pre>{popupError.stack_trace}</pre>
                                            </details>
                                            <br/>
                                        </div>
                                    }
                                    {
                                        !popupError.report_settings ? null : 
                                        <div>
                                            <b>Report settings:</b> 
                                            <details>
                                                <summary>Click to expand</summary>
                                                <pre>{popupError.report_settings}</pre>
                                            </details>
                                        </div>
                                    }
                                </div>

                            : null
                        }
					    
                        <br />
					
						<button className="bg-green-700 hover:bg-green-900 text-white font-bold py-2 px-4 rounded disabled:bg-green-300 disabled:hover:bg-green-300 mr-2"
                         onClick={() => setOpenedError(-1)}>
							OK
            			</button>
					</div>
                    </div>
				</Dialog>
                <Dialog
                    open={openedArchiveError > -1}
                    onClose={() => setOpenedArchiveError(-1)}
                    className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen">
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        
                        <div className="relative bg-white rounded max-w-sm mx-auto p-5 drop-shadow-lg">
					<Dialog.Title className="text-2xl">
                        Archive
                    </Dialog.Title>
                    <br />
                        Move this error message to archived?
                        <br /><br />
						<button className="bg-green-700 hover:bg-green-900 text-white font-bold py-2 px-4 rounded disabled:bg-green-300 disabled:hover:bg-green-300 mr-2"
                            onClick={() => {archiveError(openedArchiveError);} }>
							OK
            			</button>
                        &nbsp;                        
                        <button className="bg-gray-700 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded disabled:bg-gray-300 disabled:hover:bg-gray-300 mr-2"
                            onClick={() => {setOpenedArchiveError(-1)} }>
							Cancel
            			</button>

                        </div></div>
				</Dialog>
                <Dialog
                    open={openedArchiveMultiple}
                    onClose={() => setOpenedArchiveMultiple(false)}
                    className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen">
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        
                        <div className="relative bg-white rounded max-w-sm mx-auto p-5 drop-shadow-lg">
					<Dialog.Title className="text-2xl">
                        Archive multiple errors
                    </Dialog.Title>
                    <br />
                        Archive all {selectedErrors.length} errors?
                        <br /><br />
						<button className="bg-green-700 hover:bg-green-900 text-white font-bold py-2 px-4 rounded disabled:bg-green-300 disabled:hover:bg-green-300 mr-2"
                            onClick={() => { archiveMultipleErrors() }}>
							OK
            			</button>
                        &nbsp;                        
                        <button className="bg-gray-700 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded disabled:bg-gray-300 disabled:hover:bg-gray-300 mr-2"
                            onClick={() => {setOpenedArchiveMultiple(false)} }>
							Cancel
            			</button>
                        </div></div>
				</Dialog>

    </div >



}

export default CloudErrors