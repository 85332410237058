import { useEffect, useState } from 'react'
import { BiCheck } from 'react-icons/bi'
import { BsCheckCircle } from 'react-icons/bs'
import { MdCheck, MdCheckCircle, MdCheckCircleOutline } from 'react-icons/md'


export type SimpleToastProps = {
    type: "danger" | "warning" | "success",
    text: string | null,
    show: boolean,
}

function SimpleToast(props: SimpleToastProps) {

    const warningConfig = {
        bg: "bg-yellow-100",
        text: "text-yellow-800",
        border: "border-yellow-300",
        closeBtn: "text-yellow-800 border-yellow-800 hover:bg-yellow-600 focus:ring-${getAlertColor()}-300",
        activeBtn: "bg-yellow-800 hover:bg-yellow-900 focus:ring-yellow-300"
    }

    const dangerConfig = {
        bg: "bg-red-100",
        text: "text-red-800",
        border: "border-red-300",
        closeBtn: "text-red-800 border-red-800 hover:bg-red-600 focus:ring-${getAlertColor()}-300",
        activeBtn: "bg-red-800 hover:bg-red-900 focus:ring-red-300"
    }
    const successConfig = {
        bg: "bg-green-100",
        text: "text-green-800",
        border: "border-green-300",
        closeBtn: `text-green-800 border-green-800 hover:bg-green-600 focus:ring-${() => getAlertColor()}-300`,
        activeBtn: "bg-green-800 hover:bg-green-900 focus:ring-green-300"
    }

    const getAlertColor = () => {
        switch (props.type) {
            case "success":
                return successConfig
            case "danger":
                return dangerConfig
            case "warning":
                return warningConfig

            default:
                return warningConfig

        }
    }


    const [type, setType] = useState("warning")
    const [cssConfig, setCssConfig] = useState(getAlertColor())


    useEffect(() => {
        if (props.type !== type) {
            setCssConfig(getAlertColor())
            setType(props.type)
        }
    }, [cssConfig, type, props.type],)

    return (
        props.show ? <div id="toast-simple" className={`${cssConfig.bg} fixed left-24 flex items-center w-full max-w-xs p-4 space-x-4 rtl:space-x-reverse text-gray-500 bg-white divide-x rtl:divide-x-reverse divide-gray-200 rounded-lg shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800`} role="alert">
            <span className={`${cssConfig.text} text-xl`}>

                {props.type === "success" && <BsCheckCircle></BsCheckCircle>}
            </span>

            <div className={` text-sm font-normal ${cssConfig.text}`}>{props.text}</div>
        </div>
            : <></>
    )

}

export default SimpleToast
