import React from 'react'

import '../css/MultiSelectBar.css'

export interface MultiSelectBarProps {
    type: string,
    selected: any[],
    className?: string,
    style?: React.CSSProperties,
    options: MultiSelectOption[],
    onDeselectClick: (selected?: any[]) => void,
}

export interface MultiSelectOption {
    label: string | JSX.Element,
    onClick: (selected?: any[]) => void,
    type?: MultiSelectButtonType,
    disable?: boolean
}

export enum MultiSelectButtonType {
    DEFAULT = "DEFAULT",
    WARNING = "WARNING"
}

const MultiSelectBar = (props: MultiSelectBarProps) => {
    let getTypeString = () => {
        return props.selected.length == 1 ? props.type : `${props.type}s`
    }

    let getStyling = (type: MultiSelectButtonType) => {
        switch (type) {
            case MultiSelectButtonType.DEFAULT:
                return 'btn-outline-primary'
            case MultiSelectButtonType.WARNING:
                return 'btn-outline-danger'
        }
    }

    return (
        props.selected.length > 0 ?

            <div className={`multiselect-bar ${props.className ?? ''}`} style={props.style}>
                <div className='multiselect-left'>
                    You have selected <span style={{ color: "#1e3d58", fontWeight: "bold" }}>{props.selected.length}</span> {getTypeString()}
                    <button className="py-1 px-5 mr-2 ml-2 mb-0 text-md font-normal text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:z-10 focus:ring-4 focus:ring-gray-200 " onClick={() => props.onDeselectClick()}>Deselect</button>
                </div>
                <div className='multiselect-right'>
                    {props.options.map((o, i) => {
                        return <button key={i} disabled={o.disable ?? false} className={`py-1 px-5 mr-2 mb-0 text-md font-normal text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:z-10 focus:ring-4 focus:ring-gray-200   ${o.type ? getStyling(o.type) : ""}`} onClick={() => o.onClick(props.selected)}>
                            {o.label}
                        </button>
                    })}
                </div>
            </div>

            : <></>

    )
}

export default MultiSelectBar