import React, { useCallback, useContext, useEffect, useState } from "react"
import { get, post } from "../helpers/Requests"
import { BsCheckCircleFill } from "react-icons/bs"
import { AiFillMinusCircle, AiOutlineSearch } from "react-icons/ai"
import { Dialog } from "@headlessui/react"
import { Outlet, useNavigate, useOutletContext, useParams } from "react-router-dom"
import { CompanyData, NewCompanyProps, ServerData } from "../types/types"
import { useSideBarMenuContext } from "../components/Sidebar/SideBarContext"
import { CloudOrgInfo, CloudOrganization, CloudServerOrganization, CloudUser } from "../types/cloud_types"
import { OrgContext } from "../contexts/OrgContext"
import Organization from "./Organization"
import { useOrgs } from "./Organizations"
import { Table, TableBody, TableBodyCell, TableBodyRow, TableHead, TableHeadCell, TableHeadRow } from "../components/Table"
import { MdCheckCircle, MdDangerous } from "react-icons/md"
import { useAppContext } from "../App"
import { useStore } from "../contexts/StoreContext"
type ContextType =
    {
        org: CloudOrgInfo,
        orgItems?: any,
    };

const OrganizationsOverview = () => {
    const navigate = useNavigate()
    const [addingCompany, setAddingCompany] = useState(false)
    const {orgs, servers} = useStore()
    const {orgClickedHandler, selectedOrg, updateOrg} = useOrgs()
    const [companies, setCompanies] = useState<CompanyData[]>([])
    const [selectedOrgAndServer, setSelectedOrgAndServer] = useState<string>("")
    const [selectedServer, setSelectedServer] = useState<string>("")
    const [users, setUsers] = useState<CloudUser[]>([])
    const [cloudServerOrganizations, setCloudServerOrganizations] = useState<CloudServerOrganization[]>([]);
    const [searchText, setSearchText] = useState("")
    const [filteredOrgs, setFilteredOrgs] = useState<CloudOrgInfo[]>([])
    const searchProperties = ["name", "fk_company_id"]


    useEffect(() => { // Runs once
        if (!!orgs) {
            const filtered = orgs.filter((o: CloudOrgInfo) => !o.deleted)
            setFilteredOrgs(filtered)
        }

    }, [orgs])
    
    const orgSearch = (query:string) => {
        console.log(orgs)

            setTimeout(() => {

                if (!query) {
                    setFilteredOrgs(orgs)

                } else {

                    const filtered = orgs.filter((org) => {
                        const values = Object.entries(org)
                        return values.some((entry) => {
                            return typeof entry[1] === "string" && entry[1].toLowerCase().includes(query.toLowerCase()) && searchProperties.includes(entry[0])
                        })
                    })
                    setFilteredOrgs(filtered)
                }
            }, 200)
    }

    const handleSearchTextChange = (event:any) => {
        setSearchText(event.target.value)
        orgSearch(event.target.value)
    }

    return (

        <>
            <div className="p-4 max-w-[1400px] my-0 mx-auto">
                    {!!useParams()?.orgId && !!selectedOrg ?

                        <Outlet context={{ org: selectedOrg,
                        orgItems: null, updateOrg:updateOrg }} />

                        :

                    <>
            
                        <header className="flex justify-between mb-5 flex-wrap">
                            <h1 className="text-4xl">Organizations</h1>
                            <label htmlFor="table-search" className="sr-only">Search</label>
                            <div className="pb-4 ">
                                        <div className="relative mt-1 shadow-md rounded-lg ">
                                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                        <AiOutlineSearch size="1.1em" color="gray" />
                                    </div>
                                    <input type="text" id="table-search" value={searchText} onChange={handleSearchTextChange} className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search for organizations" />
                                </div>
                            </div>
                        </header>

                        <div className="overflow-x-auto max-w-[1000px] rounded-lg border shadow-md">

                                <table className=" divide-y divide-gray-200 ">

                                    <TableHead className="rounded-table text-gray-700 bg-gray-100 dark:bg-gray-700 dark:text-gray-400 rounded-lg">
                                <TableHeadRow className="">
                                            <TableHeadCell scope="col" className="normal-case text-[14px]">Name</TableHeadCell>
                                            <TableHeadCell scope="col" className="normal-case text-center text-[14px]"># of Users</TableHeadCell>
                                            <TableHeadCell scope="col" className="normal-case text-center text-[14px]"># of Buckets</TableHeadCell>
                                            <TableHeadCell scope="col" className="normal-case text-center text-[14px]">External ID</TableHeadCell>
                                            <TableHeadCell scope="col" className=" normal-case text-center text-[14px]">Setup status</TableHeadCell>
                                </TableHeadRow>
                                </TableHead>
                                    <TableBody className="rounded-table">
                                {filteredOrgs.map(o => (
                                    <tr key={o.id} className=" bg-white hover:bg-gray-50 border-t dark:bg-gray-800 dark:border-gray-700 hover:cursor-pointer dark:text-gray-50 dark:hover:bg-gray-900" >
                                        <TableBodyCell className="w-[350px] max-w-[350px] truncate" onClick={() => orgClickedHandler(o.id + "", o.server?.id + "")}>{o.name}</TableBodyCell>
                                        <TableBodyCell className="text-center w-[200px]" onClick={() => orgClickedHandler(o.id + "", o.server?.id + "")}>{o.user_count} </TableBodyCell>
                                        <TableBodyCell className="text-center w-[200px]" onClick={() => orgClickedHandler(o.id + "", o.server?.id + "")}>{o.bucket_count}</TableBodyCell>
                                        <TableBodyCell className="text-center w-[200px]" onClick={() => orgClickedHandler(o.id + "", o.server?.id + "")}>{o.fk_company_id}</TableBodyCell>
                                        <TableBodyCell className="text-center w-[200px] flex justify-center" onClick={() => navigate(`/apa/${o.account?.serverId}/${o.account?.id}`)}>{o.quarantined ? <MdDangerous className="w-5 h-5 text-center text-red-700 opacity-80" /> : <MdCheckCircle className="w-5 h-5 text-green-700 opacity-80" />}</TableBodyCell>
                                    </tr>
                                ))}
                            </TableBody>
                        </table>
                    </div>
                        </>
                }
            </div >

        </>                   
    )
}

export default OrganizationsOverview

export function useOrg() {
    return useOutletContext<ContextType>()
}
