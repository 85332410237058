export interface ReportingItem {
    [key: string]: any;
    id: number;
    dashboard_id?: number;
    org_id?: number;
    report_id?: number;
    email: string;
    firstname: string;
    lastname: string;
    email_text: string;
    email_title: string;
    plan_id?: number;
    last_sent: number;
    link: boolean;
    active: boolean
}

export interface Dashboard extends ReportingItem {
    dashboard_id: number;    
    include_table: boolean;
}

export interface DashboardTable extends ReportingItem{
    dashboard_id: number;
}

export interface TrendView extends ReportingItem{
    report_id: number;
    setting_hash: string;
}

export interface Report extends ReportingItem {
    org_id: number;
    report_id: number;
}

export interface Plan {
    id: number;
    interval: string;
    hour: number;
    minute: number;
    description: string;
}


export interface ReportingStats {
    daily: number;
    weekly: number;
    monthly: number;
}

export interface CloudOrgWithReporting {
    id: number;
    name: string;
    deleted: boolean;
    dashboards: Dashboard[];
    dashboardtables: DashboardTable[];
    trendviews: TrendView[];
    reports: Report[];
    plans: Plan[]
}

export const newReportingItem = (orgId: number): ReportingItem => { return { id: 0, org_id: orgId, email: '', email_text: '', email_title: '', firstname: '', lastname: '', last_sent: 0, link: false, active: true } };