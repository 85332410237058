import { MdLogout } from 'react-icons/md'
import { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { BiArrowBack } from 'react-icons/bi'
import { useSideBarMenuContext } from './SideBarContext'
import { clearToken, get } from '../../helpers/Requests'
import { useAppContext } from '../../App'
export interface SideBarMenuItem {
    icon: JSX.Element,
    text: string,
    link: string,
}

const SideBar = () => {
    const { items, up } = useSideBarMenuContext()
    const { user } = useAppContext()

    const logoutHandler = useCallback(() => {
        get('logout')
            .finally(() => {
                clearToken()
                window.location.href = '/'
            })
    }, [])

    return (
        <div className="fixed top-0 h-screen w-20 m-0 flex flex-col bg-dark-forest-900 text-white shadow-lg">
            {up && (<><SideBarIcon text={`Up`} icon={<BiArrowBack />} link={up} /><hr className='border-gray-700 m-2' /></>)}

            {items.map((item, i) => (
                <SideBarIcon key={i} {...item} />
            ))}

            <hr className='border-gray-500 m-2' />

            <SideBarIcon icon={<MdLogout size="1.4em" />} text={`Logout`} onClick={logoutHandler} />
        </div>
    )
}

interface IconProps {
    icon: any
    text?: string
    onClick?: any
    link?: string
}

const SideBarIcon: React.FC<IconProps> = ({ link = null, icon, text = 'my fancy tooltip 🐴', onClick = () => { } }) => {
    const core = (
        <div className="sidebar-icon group flex flex-col w-full" onClick={onClick}>
            {icon}
            <span className="text-[0.6em]">{text}</span>
            {/* <span className="sidebar-tooltip group-hover:scale-100">{text}</span> */}
        </div>
    )
    if (link !== null) {
        return <Link to={link}>{core}</Link>
    }
    return core
}

export default SideBar