import React from "react"

const TabBar: React.FC<{
    selected: number,
    setTab: (tab: number) => void,
    tabs: string[],
    labels?: number[],
    className?: string,
    style?: React.CSSProperties
}> = ({ selected, setTab, tabs, labels, className, style }) => {
    return <div className={`flex flex-row border-b border-slate-200 space-x-8 text-sm text-slate-500 font-semibold mb-10 tab-bar ${className}`} style={style}>
        {tabs.map((name, i) => <div key={i} onClick={_ => setTab(i)} className={`flex flex-row px-1 gap-3 ${selected === i ? "border-b-2 border-tangerine-900 text-dark-forest-900 -mb-[1px] font-bold" : "border-b-2 border-transparent hover:border-slate-300 -mb-[1px]"}`}>
            <div>{name}</div>
            {labels && <div className={`px-2 py-0.5 text-xs rounded-full ${selected === i ? "bg-indigo-100" : "bg-slate-100"}`}>{labels[i]}</div>}
        </div>)}
    </div>
}

export default TabBar